import React,{useState, useRef, useEffect} from "react";
import useFilterResponse from "../../../../hooks/useFilterResponse";
import { getNotes } from "../../../../api/api";
import useNoteVisibility from "../../../../hooks/useNoteVisibility";
import Note from "../../../../components/note";
import useTableHeight from "../../../../hooks/useTableHeight";
import { useThreshold } from "../../../../hooks/useThreshold";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import useMUIFilter from "../../../../hooks/useMUIFilter";
import ExportToolbar from "../../../../components/exportToolBar";
import tooltips from "../../../../config/tooltips.json";
import { Tooltip, Typography } from '@mui/material';
import Fade from '@mui/material/Fade';
import adjustColumnWidth from "../../../../functions/columns";

const TurnoversTable = ({ data }) => {

  const [pageWidth, setPageWidth] = useState(null)
  const [width, setWidth] = useState(0);
  const elementRef = useRef(null);

  let functionDelaytimeOut

  window.addEventListener('resize', (size) => {
    let currentTarget = size?.currentTarget?.innerWidth
    if (functionDelaytimeOut) {
      clearTimeout(functionDelaytimeOut);
    }
    functionDelaytimeOut = setTimeout(() => {
      setPageWidth(currentTarget);
    }, 800);
  });

  useEffect(() => {
    setWidth(elementRef.current.getBoundingClientRect().width);
    return () => {
      if (functionDelaytimeOut) {
        clearTimeout(functionDelaytimeOut);
      }
    };
  }, [pageWidth]);

  const vwToPx = (vw) => (vw * width) / 100;

  const columnGroupingModel = [
    {
      groupId: `${data[0].month1.name}0`,
      headerName: `${data[0].month1.name}`,
      headerAlign: 'center',
      children: [{ field: 'month1Expected' }, { field: 'month1Schedule' }, { field: 'month1possible' }],
    },
    {
      groupId: `${data[0].month2.name}1`,
      headerName: `${data[0].month2.name}`,
      headerAlign: 'center',
      children: [{ field: 'month2Expected' }, { field: 'month2Schedule' }, { field: 'month2possible' }],
    },
    {
      groupId: `${data[0].month3.name}2`,
      headerName: `${data[0].month3.name}`,
      headerAlign: 'center',
      children: [{ field: 'month3Expected' }, { field: 'month3Schedule' }, { field: 'month3possible' }],
    },
    {
      groupId: `${data[0].month4.name}3`,
      headerName: `${data[0].month4.name}`,
      headerAlign: 'center',
      children: [{ field: 'month4Expected' }],
    },
    {
      groupId: `${data[0].month5.name}4`,
      headerName: `${data[0].month5.name}`,
      headerAlign: 'center',
      children: [{ field: 'month5Expected' }],
    },
    {
      groupId: `${data[0].month6.name}5`,
      headerName: `${data[0].month6.name}`,
      headerAlign: 'center',
      children: [{ field: 'month6Expected' }],
    },
  ];

  const columnList = [
    {name: "Property", type:'singleSelect'},
    {name: "Turnover Rate", type:'number', valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `${params.value.toFixed(1).toLocaleString()} %`;}},
    {name: "Turns > 30 Days", type:'number'},
    {name: "Current Turns", type:'number'},
    {name: `${data[0].month1.name} Expected`},
    {name: `${data[0].month1.name} Schedule`},
    {name: `${data[0].month1.name} Possible`},
    {name: `${data[0].month2.name} Expected`},
    {name: `${data[0].month2.name} Schedule`},
    {name: `${data[0].month2.name} Possible`},
    {name: `${data[0].month3.name} Expected`},
    {name: `${data[0].month3.name} Schedule`},
    {name: `${data[0].month3.name} Possible`},
    {name: `${data[0].month4.name} Expected`},
    {name: `${data[0].month5.name} Expected`},
    {name: `${data[0].month6.name} Expected`}
  ];

const columnNames = adjustColumnWidth(columnList, 100, 9)

  data = data.map((val, index)=>{
    return {
id: index,
propertyID: val.propertyID,
name:val.name,
turnoverRate: val.turnoverRate,
turns30Days: val.turns30Days,
currentTurns: val.currentTurns,
month1Expected: val.month1.expect,
month1Schedule: val.month1.schedule,
month1possible: val.month1.possible,
month2Expected: val.month2.expect,
month2Schedule: val.month2.schedule,
month2possible: val.month2.possible,
month3Expected: val.month3.expect,
month3Schedule: val.month3.schedule,
month3possible: val.month3.possible,
month4Expected: val.month4.expect,
month5Expected: val.month5.expect,
month6Expected: val.month6.expect,
    }
  })

  const thresHoldValues = {
    turns30Days:{
      thresHoldValue: 'TurnsGreaterXDays'
    },
    month1possible:{
      thresHoldValue: 'Month2Possible'
    },
    month2possible:{
      thresHoldValue: 'Month3Possible'
    },
    month3possible:{
      thresHoldValue: 'Month4Possible'
    },
}

  const { response: listNotes, loader } = useFilterResponse(
    getNotes,
    "notes",
    "Turnovers"
  );
  const {
    showComponent,
    componentPosition,
    handleNote,
    notes,
    handleCheckFlag,
  } = useNoteVisibility(listNotes, loader);

  const height = useTableHeight(data, 90, "530px");
  const {getThresholdData} = useThreshold({view:'maintenance', table: 'Turnovers', loader})

  let { columenGen, thresHolds, notesColumnNameGen } = useMUIFilter(
    data,
    columnNames,
  );

  let columns = columenGen();

  columns = columns.map((data, index) => {
    let headerName
    if(data.headerName.includes('Expected')){
      headerName = 'Expected'
    }else if(data.headerName.includes('Schedule')){
      headerName = 'Schedule'
    }else if(data.headerName.includes('Possible')){
      headerName = 'Possible'
    }else{
      headerName = data.headerName
    }
  
    data.renderHeader = () => {
      return (
        <Tooltip
          placement="top"
          arrow
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
          title={
            <React.Fragment>
  <Typography color="inherit">{tooltips['Turnovers'][index]?.tooltip?.header}</Typography>
            </React.Fragment>
          }
        >
          <p className="MuiDataGrid-columnHeaderTitle">{headerName}</p>
        </Tooltip>
      );
    };
    return data;
  })

  useEffect(() => {
    setWidth(elementRef.current.getBoundingClientRect().width);
  }, []);

  return (
    <div className="container-table" style={{ height: height }} ref={elementRef}>
      {showComponent && (
        <div
          style={{
            position: "absolute",
            left: componentPosition.x-350,
            top: componentPosition.y,
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            padding: "10px",
            zIndex: "10",
            borderRadius: "10px",
          }}
        >
          <Note
            notes={notes}
            columnNames={notesColumnNameGen()}
            table="Turnovers"
            tableFullName="Turns"
            tab="Maintenance"
          />
        </div>
      )}
      <DataGridPremium
  className="grid-style"
  rows={data}
  columns={columns}
  pinnedColumns={{left: ['name']}}
  slots={{ toolbar: ExportToolbar}}
  disableColumnMenu={true}
  columnGroupingModel={columnGroupingModel}
  experimentalFeatures={{ columnGrouping: true }}
  getCellClassName={(params) => {
    return `${thresHolds(params, getThresholdData,thresHoldValues)} ${handleCheckFlag(params.row.propertyID, params.colDef.colNum)}`
  }}
  onCellClick={(cell, event) => {
    handleNote(
      cell.row.propertyID,
      cell.colDef.colNum,
      cell.formattedValue
    )(event);
  }}
  disableVirtualization
  hideFooter
  disableColumnSelector
  initialState={{
    aggregation: {
      model: {
        turnoverRate: 'avg',
        turns30Days:'sum',
        currentTurns: 'sum',
        month1Expected:'sum',
        month1Schedule:'sum',
        month1possible:'sum',
        month2Expected:'sum',
        month2Schedule:'sum',
        month2possible:'sum',
        month3Expected:'sum',
        month3Schedule:'sum',
        month3possible:'sum',
        month4Expected:'sum',
        month5Expected:'sum',
        month6Expected:'sum',
      },
    },
  }}
/>
    </div>
  );
};

export default TurnoversTable;

// import React from "react";
// import useFilterResponse from "../../../../hooks/useFilterResponse";
// import { getNotes } from "../../../../api/api";
// import useNoteVisibility from "../../../../hooks/useNoteVisibility";
// import Note from "../../../../components/note";
// import { percentage } from "../../../../functions/decimals";
// import useTableHeight from "../../../../hooks/useTableHeight";
// import { useThreshold } from "../../../../hooks/useThreshold";

// const TurnoversTable = ({ data }) => {
//   const totalTurnoverRate = data.reduce(
//     (acc, curr) => acc + curr?.turnoverRate,
//     0
//   );
//   const totalTurns30 = data.reduce((acc, curr) => acc + curr?.turns30Days, 0);
//   const totalCurrentTurns = data.reduce(
//     (acc, curr) => acc + curr?.currentTurns,
//     0
//   );
//   const totaloctBudget = data.reduce(
//     (acc, curr) => acc + curr?.month1?.expect,
//     0
//   );
//   const totaloctschedule = data.reduce(
//     (acc, curr) => acc + curr?.month1?.schedule,
//     0
//   );
//   const totaloctpossible = data.reduce(
//     (acc, curr) => acc + curr?.month1?.possible,
//     0
//   );

//   const totalnovBudget = data.reduce(
//     (acc, curr) => acc + curr?.month2?.expect,
//     0
//   );
//   const totalnovschedule = data.reduce(
//     (acc, curr) => acc + curr?.month2?.schedule,
//     0
//   );
//   const totalnovpossible = data.reduce(
//     (acc, curr) => acc + curr?.month2?.possible,
//     0
//   );

//   const totaldecBudget = data.reduce(
//     (acc, curr) => acc + curr?.month3?.expect,
//     0
//   );
//   const totaldecschedule = data.reduce(
//     (acc, curr) => acc + curr?.month3?.schedule,
//     0
//   );
//   const totaldecpossible = data.reduce(
//     (acc, curr) => acc + curr?.month3?.possible,
//     0
//   );

//   const totaljanBudget = data.reduce(
//     (acc, curr) => acc + curr?.month4?.expect,
//     0
//   );
//   const totalfebBudget = data.reduce(
//     (acc, curr) => acc + curr?.month5?.expect,
//     0
//   );
//   const totalmarchBudget = data.reduce(
//     (acc, curr) => acc + curr?.month6?.expect,
//     0
//   );

//   const columnNames = [
//     "Property",
//     "Turnover Rate",
//     "Turns > 30 Days",
//     "Current Turns",
//     `${data[0].month1.name} Budget`,
//     `${data[0].month1.name} Schedule`,
//     `${data[0].month1.name} Possible`,
//     `${data[0].month2.name} Budget`,
//     `${data[0].month2.name} Schedule`,
//     `${data[0].month2.name} Possible`,
//     `${data[0].month3.name} Budget`,
//     `${data[0].month3.name} Schedule`,
//     `${data[0].month3.name} Possible`,
//     `${data[0].month4.name} Budget`,
//     `${data[0].month5.name} Budget`,
//     `${data[0].month6.name} Budget`,
//   ];

//   const { response: listNotes, loader } = useFilterResponse(
//     getNotes,
//     "notes",
//     "Turnovers"
//   );
//   const {
//     showComponent,
//     componentPosition,
//     handleNote,
//     notes,
//     handleCheckFlag,
//   } = useNoteVisibility(listNotes, loader);

//   const height = useTableHeight(data, 90, "530px");
//   const {getThresholdData} = useThreshold({view:'maintenance', table: 'Turnovers', loader})

//   return (
//     <div className="container-table" style={{ height: height }}>
//       {showComponent && (
//         <div
//           style={{
//             position: "absolute",
//             left: componentPosition.x,
//             top: componentPosition.y,
//             backgroundColor: "#fff",
//             border: "1px solid #ccc",
//             padding: "10px",
//             zIndex: "10",
//             borderRadius: "10px",
//           }}
//         >
//           <Note
//             notes={notes}
//             columnNames={columnNames}
//             table="Turnovers"
//           />
//         </div>
//       )}
//       <div className="wrapper">
//         <table className="hasColors">
//           <thead>
//             <tr>
//               <th>Property</th>
//               <th className="subparent">Turnover Rate</th>
//               <th className="subparent">Turns {">"} 30 Days</th>
//               <th className="subparent">Current Turns</th>
//               <th colSpan={3} className="subparent">
//                 {data[0].month1.name}
//               </th>
//               <th colSpan={3} className="subparent">
//                 {data[0].month2.name}
//               </th>
//               <th colSpan={3} className="subparent">
//                 {data[0].month3.name}
//               </th>
//               <th className="subparent">{data[0].month4.name}</th>
//               <th className="subparent">{data[0].month5.name}</th>
//               <th className="subparent">{data[0].month6.name}</th>
//             </tr>
//             <tr className="subchild">
//               <th></th>
//               <th></th>
//               <th></th>
//               <th></th>
//               <th>Expected</th>
//               <th>Schedule</th>
//               <th>Possible</th>
//               <th>Expected</th>
//               <th>Schedule</th>
//               <th>Possible</th>
//               <th>Expected</th>
//               <th>Schedule</th>
//               <th>Possible</th>
//               <th>Expected</th>
//               <th>Expected</th>
//               <th>Expected</th>
//             </tr>
//           </thead>
//           <tbody>
//             {data?.map((item, index) => (
//               <tr key={index}>
//                 <td
//                   onClick={handleNote(item?.propertyID, 0, item?.name, columnNames)}
//                   className={`${handleCheckFlag(item?.propertyID, 0)} ${index%2 === 0 ? 'white' : ''}`}
//                 >
//                   {item?.name}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 1, item?.turnoverRate, columnNames)}
//                   className={handleCheckFlag(item?.propertyID, 1)}
//                 >
//                   {percentage(item?.turnoverRate)}%
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 2, item?.turns30Days, columnNames)}
//                   className={`${handleCheckFlag(item?.propertyID, 2)}
//                   ${getThresholdData(parseInt(item?.turns30Days), 'TurnsGreaterXDays')}`}
//                 >
//                   {item?.turns30Days}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 3, item?.currentTurns, columnNames)}
//                   className={handleCheckFlag(item?.propertyID, 3)}
//                 >
//                   {item?.currentTurns}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 4, item?.month1?.expect, columnNames)}
//                   className={handleCheckFlag(item?.propertyID, 4)}
//                 >
//                   {item?.month1?.expect}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 5, item?.month1?.schedule, columnNames)}
//                   className={handleCheckFlag(item?.propertyID, 5)}
//                 >
//                   {item?.month1?.schedule}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 6, item?.month1?.possible, columnNames)}
//                   className={`${getThresholdData(parseInt(item?.month1?.possible), 'Month2Possible')}
//                   ${handleCheckFlag(item?.propertyID, 6)}`}
//                 >
//                   {item?.month1?.possible}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 7, item?.month2?.expect, columnNames)}
//                   className={handleCheckFlag(item?.propertyID, 7)}
//                 >
//                   {item?.month2?.expect}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 8, item?.month2?.schedule, columnNames)}
//                   className={handleCheckFlag(item?.propertyID, 8)}
//                 >
//                   {item?.month2?.schedule}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 9, item?.month2?.possible, columnNames)}
//                   className={`${getThresholdData(parseInt(item?.month2?.possible), 'Month3Possible')}
//                   ${handleCheckFlag(item?.propertyID, 9)}`}
//                 >
//                   {item?.month2?.possible}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 10, item?.month3?.expect, columnNames)}
//                   className={handleCheckFlag(item?.propertyID, 10)}
//                 >
//                   {item?.month3?.expect}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 11, item?.month3?.schedule, columnNames)}
//                   className={handleCheckFlag(item?.propertyID, 11)}
//                 >
//                   {item?.month3?.schedule}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 12, item?.month3?.possible, columnNames)}
//                   className={`${getThresholdData(parseInt(item?.month3?.possible), 'Month4Possible')}
//                   ${handleCheckFlag(item?.propertyID, 12)}`}
//                 >
//                   {item?.month3?.possible}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 13, item?.month4?.expect, columnNames)}
//                   className={handleCheckFlag(item?.propertyID, 13)}
//                 >
//                   {item?.month4?.expect}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 14, item?.month5?.expect, columnNames)}
//                   className={handleCheckFlag(item?.propertyID, 14)}
//                 >
//                   {item?.month5?.expect}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 15, item?.month6?.expect, columnNames)}
//                   className={handleCheckFlag(item?.propertyID, 15)}
//                 >
//                   {item?.month6?.expect}
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//           <tfoot>
//             <tr>
//               <td>Total</td>
//               <td>{percentage(totalTurnoverRate/data.length)}%</td>
//               <td>{totalTurns30}</td>
//               <td>{totalCurrentTurns}</td>
//               <td>{totaloctBudget}</td>
//               <td>{totaloctschedule}</td>
//               <td>{totaloctpossible}</td>
//               <td>{totalnovBudget}</td>
//               <td>{totalnovschedule}</td>
//               <td>{totalnovpossible}</td>
//               <td>{totaldecBudget}</td>
//               <td>{totaldecschedule}</td>
//               <td>{totaldecpossible}</td>
//               <td>{totaljanBudget}</td>
//               <td>{totalfebBudget}</td>
//               <td>{totalmarchBudget}</td>
//             </tr>
//           </tfoot>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default TurnoversTable;

