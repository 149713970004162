import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MaintenanceTable from "../maintenance/tables/maintenanceTable";
import FilterButton from "../../components/filterButton";
import useFilterResponse from "../../hooks/useFilterResponse";
import { getMaintenanceDetail } from "../../api/api";
import CircularProgress from "@mui/material/CircularProgress";

export default function MaintenanceDetail() {
  const navigate = useNavigate();
  const status = useSelector((state) => state.sidebar.value);
  const { response, loader } = useFilterResponse(getMaintenanceDetail, "workOrderDetail");

  const handleRedirectMaintenanceButton = () => navigate("/maintenance");

  return (
    <div id="main">
      <div className={`content ${status}`}>
        <div className="maintenance-container">
          <div className={`child ${status}`}>
            <FilterButton hasTitle={true} title="Maintenance" />
            <div className="line"></div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "1rem",
              }}
            >
              <button onClick={handleRedirectMaintenanceButton}>
                {"<"} BACK
              </button>
              {/* <select></select> */}
            </div>
            {loader ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </div>
            ) : (
              <>
              {response.length > 0 ? <MaintenanceTable data={response} />:<h1 style={{textAlign:'center',height:'80%'}}>Based on the current data, there are no active work orders.<br></br><br></br>Please note that this assessment is subject to change with new information or circumstances.</h1>}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
