import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    show: false,
    text: '',
    severity: 'error',
};

const snackbarSlice = createSlice({
    name: 'snackbar',
    initialState,
    reducers: {
        setSnackbar: (state, action) => {
            state.show = true;
            state.text = action.payload.text;
            state.severity = action.payload.severity;
        },

        clearSnackbar: (state) => {
            state.show = false;
            state.text = '';
            state.severity = 'error';
        },
    },
});

export const { setSnackbar, clearSnackbar } = snackbarSlice.actions;

export default snackbarSlice.reducer