import React, {useState, useRef, useEffect} from "react";
import { getNotes } from "../../../../api/api";
import useTableHeight from "../../../../hooks/useTableHeight";
import useNoteVisibility from "../../../../hooks/useNoteVisibility";
import useFilterResponse from "../../../../hooks/useFilterResponse";
import { DataGridPremium, useGridApiRef } from '@mui/x-data-grid-premium';
import useMUIFilter from "../../../../hooks/useMUIFilter";
import Note from "../../../../components/note";
import ExportToolbar from "../../../../components/exportToolBar";
import adjustColumnWidth from "../../../../functions/columns";

const Schedules = ({ data }) => {

data = data.map((data)=>{
  return {
    id: data.id,
    propertyID: data.propertyID,
    ...data
  }
})

  const height = useTableHeight(data)

  const [pageWidth, setPageWidth] = useState(null)
  const [width, setWidth] = useState(0);
  const elementRef = useRef(null);

  let functionDelaytimeOut

  window.addEventListener('resize', (size) => {
    let currentTarget = size?.currentTarget?.innerWidth
    if (functionDelaytimeOut) {
      clearTimeout(functionDelaytimeOut);
    }
    functionDelaytimeOut = setTimeout(() => {
      setPageWidth(currentTarget);
    }, 800);
  });

  useEffect(() => {
    setWidth(elementRef.current.getBoundingClientRect().width);
    return () => {
      if (functionDelaytimeOut) {
        clearTimeout(functionDelaytimeOut);
      }
    };
  }, [pageWidth]);

  
  const apiRef = useGridApiRef();


  const column = [
    {name:"Property", type: "singleSelect"},
    {name:"Vacant", type:'number'},
    {name:"Move Outs", type: "number"},
    {name:"Move Ins", type:'number'},
    {name:"Approved Apps", type:'number'},
    {name:"Pending Apps", type:'number'},
    {name:"Pending Renewals", type:'number'},
    {name:"Expected Vacancies", type:'number'},
  ];

  const columnNames = adjustColumnWidth(column, 110, 14)

  let { columenGen } = useMUIFilter(
    data,
    columnNames,
    "Scheduled Move Ins & Move Outs"
  );

  let columns = columenGen();

  const { response: listNotes, loader } = useFilterResponse(
    getNotes,
    "notes",
    "moveInsAndOuts"
  );
  let notescolumns = columnNames.map((col)=>{
    return col.name
  })
  const {
    showComponent,
    componentPosition,
    handleNote,
    notes,
    handleCheckFlag,
  } = useNoteVisibility(listNotes, loader);
 
  return (
    <div className="container-table_schedules" style={{ height: height }} id='ScheduledMoveIns&MoveOut' ref={elementRef}>

{showComponent && (
        <div
          style={{
            position: "absolute",
            left: componentPosition.x-350,
            top: componentPosition.y,
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            padding: "10px",
            zIndex: "10",
            borderRadius: "10px",
          }}
        >
          <Note
            notes={notes}
            columnNames={notescolumns}
            table="moveInsAndOuts"
            tableFullName="Scheduled Move Ins & Move Outs"
            tab="Occupancy"
          />
        </div>
      )}  
<DataGridPremium
  className="grid-style"
  apiRef={apiRef}
  rows={data}
  columns={columns}
  onCellClick={(cell, event) => {
    handleNote(
      cell.row.propertyID,
      cell.colDef.colNum,
      cell.formattedValue
    )(event);
  }}
  slots={{ toolbar: ExportToolbar}}
  disableColumnMenu={true}
  pinnedColumns={{left: ['name']}}
  disableVirtualization
  hideFooter
  disableColumnSelector
  getCellClassName={(params) => {
    return `${handleCheckFlag(params.row.propertyID, params.colDef.colNum)}`
  }}
  initialState={{
    aggregation: {
      model: {
        vacant: 'sum',
        moveOuts: 'sum',
        moveIns:'sum',
        approvedApps:'sum',
        pendingApps:'sum',
        pendingRenewals:'sum',
        expect:'sum',
      },
    },
  }}
/>
    </div>
  );
};

export default Schedules;


// import React from "react";
// import useTableHeight from "../../../../hooks/useTableHeight";

// const Schedules = ({ data }) => {
//   const totalvacant = data?.reduce((acc, curr) => acc + curr?.vacant, 0);
//   const totalmoveOuts = data?.reduce((acc, curr) => acc + curr?.moveOuts, 0);
//   const totalmoveIns = data?.reduce((acc, curr) => acc + curr?.moveIns, 0);
//   const totalapprovedApps = data?.reduce(
//     (acc, curr) => acc + curr?.approvedApps,
//     0
//   );
//   const totalpendingApps = data?.reduce(
//     (acc, curr) => acc + curr?.pendingApps,
//     0
//   );
//   const totalexpect = data?.reduce((acc, curr) => acc + curr?.expect, 0);
//   const total = data?.reduce((acc, curr) => acc + curr?.pendingRenewals, 0);

//   const height = useTableHeight(data)

//   return (
//     <div className="container-table" style={{ height: height }} id='ScheduledMoveIns&MoveOut'>
//       <div className="wrapper">
//         <table>
//           <thead>
//             <tr>
//               <th>Property</th>
//               <th>Vacant</th>
//               <th>Move Outs</th>
//               <th>Move Ins</th>
//               <th>Approved Apps</th>
//               <th>Pending Apps</th>
//               <th>Pending Renewals</th>
//               <th>Expected Vacancies</th>
//             </tr>
//           </thead>
//           <tbody>
//             {data?.map((item, index) => (
//               <tr key={index}>
//                 <td className={`${index%2 === 0 ? 'white' : ''}`}>{item?.name}</td>
//                 <td>{item?.vacant}</td>
//                 <td>{item?.moveOuts}</td>
//                 <td>{item?.moveIns}</td>
//                 <td>{item?.approvedApps}</td>
//                 <td>{item?.pendingApps}</td>
//                 <td>{item?.pendingRenewals}</td>
//                 <td>{item?.expect}</td>
//               </tr>
//             ))}
//           </tbody>
//           <tfoot>
//             <tr>
//               <td>Total</td>
//               <td>{totalvacant}</td>
//               <td>{totalmoveOuts}</td>
//               <td>{totalmoveIns}</td>
//               <td>{totalapprovedApps}</td>
//               <td>{totalpendingApps}</td>
//               <td>{total}</td>
//               <td>{totalexpect}</td>
//             </tr>
//           </tfoot>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default Schedules;
