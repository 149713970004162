import { configureStore } from '@reduxjs/toolkit'
import sidebarSlice from './storage/sidebarSlice'
import filterSlice from './storage/filterSlice'
import authSlice from './storage/authSlice'
import stateSlice from './storage/stateSlice'
import noteSlice from './storage/noteSlice'
import filterMoreDetailsSlice from './storage/filterMoreDetailsSlice'
import thresholdSlice from './storage/thresholdsSlice'
import snackbarReducer from './storage/snackbarSlice'

export default configureStore({
  reducer: {
    sidebar: sidebarSlice,
    filter: filterSlice,
    auth: authSlice,
    state: stateSlice,
    note: noteSlice,
    filterMoreDetails: filterMoreDetailsSlice,
    threshold: thresholdSlice,
    snackbar: snackbarReducer,
  },
})