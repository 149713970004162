import React,{useEffect, useRef, useState} from "react";
import useFilterResponse from "../../../../hooks/useFilterResponse";
import { getNotes } from "../../../../api/api";
import useNoteVisibility from "../../../../hooks/useNoteVisibility";
import Note from "../../../../components/note";
import { useThreshold } from "../../../../hooks/useThreshold";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import useMUIFilter from "../../../../hooks/useMUIFilter";
import ExportToolbar from "../../../../components/exportToolBar";
import CellWithTracer from "../../../../components/cellTracer/CellTracer";
import tooltips from "../../../../config/tooltips.json";
import { Tooltip, Typography } from '@mui/material';
import Fade from '@mui/material/Fade';
import adjustColumnWidth from "../../../../functions/columns";

export const MoreDetails = ({ data }) => {

  const [width, setWidth] = useState(0);
  const elementRef = useRef(null);

  useEffect(() => {
    setWidth(elementRef.current.getBoundingClientRect().width);
  }, []);

  const column = [
    {name: "Property", type:'singleSelect'},
    {name: "Account Group", type:'singleSelect'},
    {name: "Account #", type:'singleSelect'},
    {name: "Account Name", type:'singleSelect'},
    {name: "Remaining this month", valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }else if(typeof params.value === 'number'){
        return `$${params.value.toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
      }
      return params.value;
      }},
    {name: "Month to Date Actual", type:'number', valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `$${params.value.toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;}},
    {name: "Month to Date Budget", type:'number', valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `$${params.value.toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;}},
    {name: "Month to Date $ Variance", type:'number', valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `$${params.value.toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;}},
    {name: "Month to Date % Variance", type:'number', valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `${params.value.toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}%`;}},
    {name: "Year to Date Actual", type:'number', valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `$${params.value.toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;}},
    {name: "Year to Date Budget", type:'number', valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `$${params.value.toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;}},
    {name: "Year to Date $ Variance", type:'number', valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `$${params.value.toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;}},
    {name: "Year to Date % Variance", type:'number', valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `${params.value.toFixed(0).toLocaleString()}%`;}},
    {name: "Annual Budget", type:'number', valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `$${params.value.toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;}},
  ];

  const columnNames = adjustColumnWidth(column, 60, 8);

  const columnGroupingModel = [
    {
      groupId: `Month to Date`,
      headerName: `Month to Date`,
      headerAlign: 'center',
      children: [{ field: 'monthToDateActual' }, { field: 'monthToDateBudget' }, { field: 'monthToDateVariance' }, { field: 'monthToDateVariancePercent' }],
    },
    {
      groupId: `Year to Date`,
      headerName: `Year to Date`,
      headerAlign: 'center',
      children: [{ field: 'yearToDateActual' }, { field: 'yearToDateBudget' }, { field: 'yearToDateVariance' }, { field: 'yearToDateVariancePercent' }],
    },
  ]

  const { response, loader } = useFilterResponse(
    getNotes,
    "notes",
    "BudgetDetails"
  );
  const {
    showComponent,
    componentPosition,
    handleNote,
    notes,
    handleCheckFlag,
    handleCheckFlagforAccounts
  } = useNoteVisibility(response, loader);
  const { getThresholdData } = useThreshold({
    view: "budget",
    table: "MoreDetailsBudget",
    loader,
  });

  const handleConditionalMonthVariance = (type, value) => {
    if (type === "Expenses")
      return getThresholdData(
        parseInt(value),
        "MonthToDatePErcentVarianceExpense"
      );
    else
      return getThresholdData(
        parseInt(value),
        "MonthToDatePercentVarianceIncome"
      );
  };

  const handleConditionalYearVariance = (type, value) => {
    if (type === "Expenses")
      return getThresholdData(
        parseInt(value),
        "YearToDatePErcentVarianceExpense"
      );
    else
      return getThresholdData(
        parseInt(value),
        "YearToDatePercentVarianceIncome"
      );
  };

  let { columenGen, thresHolds, notesColumnNameGen } = useMUIFilter(data, columnNames);

  let columns = columenGen();

  columns = columns.map((data, index) => {
    let headerName
    if(data.headerName.includes('Actual')){
      headerName = 'Actual'
    }else if(data.headerName.includes('Budget')){
      headerName = 'Budget'
    }else if(data.headerName.includes('$ Variance')){
      headerName = '$ Variance'
    }else if(data.headerName.includes('% Variance')){
      headerName = '% Variance'
    }else{
      headerName = data.headerName
    }
  
    data.renderHeader = () => {
      if(tooltips['Budget Variance'][index]?.tooltip?.header !== null){
      return (
        <Tooltip
          placement="top"
          arrow
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
          title={
            <React.Fragment>
  <Typography color="inherit">{tooltips['Budget Variance'][index]?.tooltip?.header}</Typography>
  {tooltips['Budget Variance'][index].tooltip.text}
            </React.Fragment>
          }
        >
          <p className="MuiDataGrid-columnHeaderTitle">{headerName}</p>
        </Tooltip>
      );
    }else{
return (<p className="MuiDataGrid-columnHeaderTitle">{headerName}</p>)
    }
  };
    return data;
  });

  return (
    <div className="container-table-borders" style={{ height: '66vh' }} ref={elementRef}>
      {showComponent && (
        <div
          style={{
            position: "absolute",
            left: componentPosition.x-350,
            top: componentPosition.y,
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            padding: "10px",
            zIndex: "10",
            borderRadius: "10px",
          }}
        >
          <Note notes={notes} columnNames={notesColumnNameGen()} table="BudgetDetails" tableFullName="Variance" tab="Budget"/>
        </div>
      )}
      {/* <div className="wrapper"> */}
      <div>
      <DataGridPremium style={{ height: '66vh' }}
sx={{
  '&&& .MuiDataGrid-cell': {
    overflowWrap: 'break-word',
    whiteSpace: 'normal',
  },
  '&&& .MuiDataGrid-columnHeader': {
    overflowWrap: 'break-word',
    whiteSpace: 'normal',
    textAlign: 'center', // Optional: align header text in the center
  },
}}
              className="grid-style"
              slots={{
                toolbar: ExportToolbar, cell:CellWithTracer,
              }}
              pinnedColumns={{left: ['name', 'accountGroup', 'accountNumber', 'accountName']}}
              rows={data}
              columns={columns}
              // getRowHeight={()=> 'auto'}
        disableColumnMenu={true}
        columnGroupingModel={columnGroupingModel}
        experimentalFeatures={{ columnGrouping: true }}
        hideFooter
        disableVirtualization
        disableColumnSelector
        getCellClassName={(params) => {
          if(params.field === 'monthToDateVariancePercent'){
            return handleConditionalMonthVariance( params.row?.accountGroup, parseInt(params.row.monthToDateVariancePercent))
          }
          if(params.field === 'yearToDateVariancePercent'){
            return handleConditionalYearVariance( params.row.accountGroup, parseInt(params.row.yearToDateVariancePercent))
          }
          return `${handleCheckFlagforAccounts(params.row.propertyID, params.colDef.colNum, params.row.accountNumber)}`
        }}
          onCellClick={(cell, event) => {
            handleNote(
              cell.row.propertyID,
              cell.colDef.colNum,
              cell.formattedValue,
              null,
              cell.row.accountNumber
              )(event);
            }}
      />
      </div>
    </div>
  );
};

//Legacy Code
// import React from "react";
// import useFilterResponse from "../../../../hooks/useFilterResponse";
// import { getNotes } from "../../../../api/api";
// import useNoteVisibility from "../../../../hooks/useNoteVisibility";
// import { money, percentage } from "../../../../functions/decimals";
// import Note from "../../../../components/note";
// import { useThreshold } from "../../../../hooks/useThreshold";

// export const MoreDetails = ({ data }) => {
//   const columnNames = [
//     {name: "Property", type:'number', width:0},
//     {name: "Account Group", type:'number', width:0},
//     {name: "Account #", type:'number', width:0},
//     {name: "Account Name", type:'number', width:0},
//     {name: "Remaining this month", type:'number', width:0},
//     {name: "Actual", type:'number', width:0},
//     {name: "Budget", type:'number', width:0},
//     {name: "$ Variance", type:'number', width:0},
//     {name: "% Variance", type:'number', width:0},
//     {name: "Actual", type:'number', width:0},
//     {name: "Budget", type:'number', width:0},
//     {name: "$ Variance", type:'number', width:0},
//     {name: "% Variance", type:'number', width:0},
//     {name: "Annual Budget", type:'number', width:0},
//   ];

//   const { response, loader } = useFilterResponse(
//     getNotes,
//     "notes",
//     "BudgetDetails"
//   );
//   const {
//     showComponent,
//     componentPosition,
//     handleNote,
//     notes,
//     handleCheckFlag,
//     handleCheckFlagforAccounts
//   } = useNoteVisibility(response, loader);
//   const { getThresholdData } = useThreshold({
//     view: "budget",
//     table: "MoreDetailsBudget",
//     loader,
//   });

//   const handleConditionalMonthVariance = (type, value) => {
//     if (type === "Expenses")
//       return getThresholdData(
//         parseInt(value),
//         "MonthToDatePErcentVarianceExpense"
//       );
//     else
//       return getThresholdData(
//         parseInt(value),
//         "MonthToDatePercentVarianceIncome"
//       );
//   };

//   const handleConditionalYearVariance = (type, value) => {
//     if (type === "Expenses")
//       return getThresholdData(
//         parseInt(value),
//         "YearToDatePErcentVarianceExpense"
//       );
//     else
//       return getThresholdData(
//         parseInt(value),
//         "YearToDatePercentVarianceIncome"
//       );
//   };

//   return (
//     <div className="container-table-borders" style={{ height: "auto" }}>
//       {showComponent && (
//         <div
//           style={{
//             position: "absolute",
//             left: componentPosition.x,
//             top: componentPosition.y,
//             backgroundColor: "#fff",
//             border: "1px solid #ccc",
//             padding: "10px",
//             zIndex: "10",
//             borderRadius: "10px",
//           }}
//         >
//           <Note notes={notes} columnNames={columnNames} table="BudgetDetails" />
//         </div>
//       )}
//       <div className="wrapper">
//         <table
//           className="hasColors"
//           style={{ tableLayout: "auto", width: "max-content", height: "auto" }}
//         >
//           <thead>
//             <tr>
//               <th className="subparent">Property</th>
//               <th className="subparent">Account Group</th>
//               <th className="subparent">Account #</th>
//               <th className="subparent">Account Name</th>
//               <th className="subparent">Remaining this month</th>
//               <th className="subparent" colSpan={4}>
//                 Month to Date
//               </th>
//               <th className="subparent" colSpan={4}>
//                 Year to Date
//               </th>
//               <th className="subparent">Annual Budget</th>
//             </tr>
//             <tr className="subchild">
//               <th></th>
//               <th></th>
//               <th></th>
//               <th></th>
//               <th></th>

//               <th>Actual</th>
//               <th>Budget</th>
//               <th>$ Variance</th>
//               <th>% Variance</th>
//               <th>Actual</th>
//               <th>Budget</th>
//               <th>$ Variance</th>
//               <th>% Variance</th>

//               <th></th>
//             </tr>
//           </thead>
//           <tbody>
//             {data?.map((item, index) => (
//               <tr key={`${item?.propertyID}-${index}`}>
//                 <td
//                   // onClick={handleNote(item?.propertyID, 0)}
//                   className={`${handleCheckFlag(item?.propertyID, 0)} ${
//                     index % 2 === 0 ? "white" : ""
//                   }`}
//                 >
//                   {item?.name}
//                 </td>
//                 <td
//                   // onClick={handleNote(item?.propertyID, 1)}
//                   className={handleCheckFlag(item?.propertyID, 1)}
//                 >
//                   {item?.accountGroup}
//                 </td>
//                 <td
//                   // onClick={handleNote(item?.propertyID, 2)}
//                   className={handleCheckFlag(item?.propertyID, 2)}
//                 >
//                   {item?.accountNumber}
//                 </td>
//                 <td
//                   // onClick={handleNote(item?.propertyID, 3)}
//                   className={handleCheckFlag(item?.propertyID, 3)}
//                 >
//                   {item?.accountName}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 4, item?.remainingThisMonth, null, item?.accountNumber)}
//                   className={`borderRight ${handleCheckFlagforAccounts(
//                     item?.propertyID,
//                     4,
//                     item?.accountNumber
//                   )}`}
//                 >
//                   {!isNaN(item?.remainingThisMonth) ? money(item?.remainingThisMonth) : item?.remainingThisMonth}
//                 </td>

//                 <td
//                   onClick={handleNote(item?.propertyID, 5, item?.monthToDate?.actual, null, item?.accountNumber)}
//                   className={handleCheckFlagforAccounts(
//                     item?.propertyID,
//                     5,
//                     item?.accountNumber
//                   )}
//                   style={{textAlign:'right'}}
//                 >
//                   {money(item?.monthToDate?.actual)}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 6, item?.monthToDate?.budget, null, item?.accountNumber)}
//                   className={handleCheckFlagforAccounts(
//                     item?.propertyID,
//                     6,
//                     item?.accountNumber
//                   )}
//                   style={{textAlign:'right'}}
//                 >
//                   {money(item?.monthToDate?.budget)}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 7, item?.monthToDate?.variance, null, item?.accountNumber)}
//                   className={handleCheckFlagforAccounts(
//                     item?.propertyID,
//                     7,
//                     item?.accountNumber
//                   )}
//                   style={{textAlign:'right'}}
//                 >
//                   {money(item?.monthToDate?.variance)}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 8, item?.monthToDate?.variancePercent, null, item?.accountNumber)}
//                   className={`borderRight ${handleCheckFlagforAccounts(
//                     item?.propertyID,
//                     8,
//                     item?.accountNumber
//                   )}
//                   ${handleConditionalMonthVariance(
//                     item?.accountGroup,
//                     parseInt(item?.monthToDate?.variancePercent)
//                   )}
//                   `}
//                 >
//                   {percentage(item?.monthToDate?.variancePercent)}%
//                 </td>

//                 <td
//                   onClick={handleNote(item?.propertyID, 9, item?.yearToDate?.actual, null, item?.accountNumber)}
//                   className={handleCheckFlagforAccounts(
//                     item?.propertyID,
//                     9,
//                     item?.accountNumber
//                   )}
//                   style={{textAlign:'right'}}
//                 >
//                   {money(item?.yearToDate?.actual)}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 10, item?.yearToDate?.budget, null, item?.accountNumber)}
//                   className={handleCheckFlagforAccounts(
//                     item?.propertyID,
//                     10,
//                     item?.accountNumber
//                   )}
//                   style={{textAlign:'right'}}
//                 >
//                   {money(item?.yearToDate?.budget)}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 11, item?.yearToDate?.variance, null, item?.accountNumber)}
//                   className={handleCheckFlagforAccounts(
//                     item?.propertyID,
//                     11,
//                     item?.accountNumber
//                   )}
//                   style={{textAlign:'right'}}
//                 >
//                   {money(item?.yearToDate?.variance)}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 12, item?.yearToDate?.variancePercent, null, item?.accountNumber)}
//                   className={`borderRight ${handleCheckFlagforAccounts(
//                     item?.propertyID,
//                     12,
//                     item?.accountNumber
//                   )}
//                   ${handleConditionalYearVariance(
//                     item?.accountGroup,
//                     parseInt(item?.yearToDate?.variancePercent)
//                   )}
//                   `}
//                 >
//                   {percentage(item?.yearToDate?.variancePercent)}%
//                 </td>

//                 <td
//                   onClick={handleNote(item?.propertyID, 13, item?.annualBudget, null, item?.accountNumber)}
//                   className={handleCheckFlagforAccounts(
//                     item?.propertyID,
//                     13,
//                     item?.accountNumber
//                   )}
//                   style={{textAlign:'right'}}
//                 >
//                   {money(item?.annualBudget)}
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//           {/*<tfoot>
//             <tr>
//             <td onClick={handleNote(item?.propertyID, 0)} className={`${handleCheckFlag(item?.propertyID, 0)} ${index%2 === 0 ? 'white' : ''}`}>{item?.name}</td>
//                 <td >{item?.accountGroup}</td>
//                 <td >{item?.accountNumber}</td>
//                 <td >{item?.accountName}</td>
//                 <td >{item?.remainingThisMonth}</td>

//                 <td >{money(item?.monthToDate?.actual)}</td>
//                 <td >{money(item?.monthToDate?.budget)}</td>
//                 <td >{money(item?.monthToDate?.variance)}</td>
//                 <td >{percentage(item?.monthToDate?.variancePercent)}%</td>

//                 <td >{money(item?.yearToDate?.actual)}</td>
//                 <td >{money(item?.yearToDate?.budget)}</td>
//                 <td >{money(item?.yearToDate?.variance)}</td>
//                 <td >{percentage(item?.yearToDate?.variancePercent)}%</td>

//                 <td >{money(item?.annualBudget)}</td>
              
//             </tr>
//           </tfoot>*/}
//         </table>
//       </div>
//     </div>
//   );
// };

