import React, { useState, useRef, useEffect } from "react";
import useTableHeight from "../../../../hooks/useTableHeight";
import useFilterResponse from "../../../../hooks/useFilterResponse";
import useNoteVisibility from "../../../../hooks/useNoteVisibility";
import { getNotes } from "../../../../api/api";
import Note from "../../../../components/note";
import { useThreshold } from "../../../../hooks/useThreshold";
import { DataGridPremium, useGridApiRef } from '@mui/x-data-grid-premium';
import useMUIFilter from "../../../../hooks/useMUIFilter";
import ExportToolbar from "../../../../components/exportToolBar";
import adjustColumnWidth from "../../../../functions/columns";

const CurrentAvailability = ({ data }) => {

  const [pageWidth, setPageWidth] = useState(null)
  const [width, setWidth] = useState(0);
  const elementRef = useRef(null);

  let functionDelaytimeOut

  window.addEventListener('resize', (size) => {
    let currentTarget = size?.currentTarget?.innerWidth
    if (functionDelaytimeOut) {
      clearTimeout(functionDelaytimeOut);
    }
    functionDelaytimeOut = setTimeout(() => {
      setPageWidth(currentTarget);
    }, 800);
  });

  useEffect(() => {
    setWidth(elementRef.current.getBoundingClientRect().width);
    return () => {
      if (functionDelaytimeOut) {
        clearTimeout(functionDelaytimeOut);
      }
    };
  }, [pageWidth]);

  const height = useTableHeight(data);
  const apiRef = useGridApiRef();

  const column = [
    { name: "Property", type: "singleSelect" },
    { name: "Units", type: 'number' },
    { name: "Vacant", type: "number" },
    {
      name: "Occupancy", type: 'number', valueFormatter: (params) => {
        if (params.value == null) {
          return '';
        }
        return `${params.value.toFixed(1).toLocaleString()} %`;
      }
    },
    { name: "Vacant Unrented Not Ready", type: 'number' },
    { name: "Vacant Unrented Ready", type: 'number' },
    { name: "Vacant > 30 Days", type: 'number' },
    { name: "Next Available", type: 'date'},
  ];

  const columnNames = adjustColumnWidth(column, 100, 10)

  const thresHoldValues = {
    occupancy: {
      thresHoldValue: 'Occupancy'
    }, moreDays: {
      thresHoldValue: 'VacantGreaterXDays'
    }
  }

  let { columenGen, thresHolds, notesColumnNameGen } = useMUIFilter(
    data,
    columnNames,
    "Current Availability"
  );

  let columns = columenGen();

  const { response: listNotes, loader } = useFilterResponse(
    getNotes,
    "notes",
    "CurrentAvailability"
  );
  const {
    showComponent,
    componentPosition,
    handleNote,
    notes,
    handleCheckFlag,
  } = useNoteVisibility(listNotes, loader);

  const { getThresholdData } = useThreshold({ view: 'leasing', table: 'CurrentAvailability', loader })

  // if (apiRef?.current?.autosizeColumns) {
  //   apiRef.current.autosizeColumns({
  //     includeHeaders: true,
  //     includeOutliers: true,
  //   });
  // }

  return (
    <div className="container-table" style={{ height: height }} ref={elementRef}>
      {showComponent && (
        <div
          style={{
            position: "absolute",
            left: componentPosition.x-350,
            top: componentPosition.y,
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            padding: "10px",
            zIndex: "10",
            borderRadius: "10px",
          }}
        >
          <Note
            notes={notes}
            columnNames={notesColumnNameGen()}
            table="CurrentAvailability"
            tableFullName="Current Availability"
            tab="Leasing"
          />
        </div>
      )}
      <DataGridPremium
        className="grid-style"
        // apiRef={apiRef}
        rows={data}
        columns={columns}
        onCellClick={(cell, event) => { handleNote(cell.row.propertyID, cell.colDef.colNum, cell.formattedValue)(event) }}
        getCellClassName={(params) => {
          return `${thresHolds(params, getThresholdData, thresHoldValues)} ${handleCheckFlag(params.row.propertyID, params.colDef.colNum)}`
        }}
        slots={{ toolbar: ExportToolbar }}
        pinnedColumns={{ left: ['name'] }}
        disableVirtualization
        hideFooter
        disableColumnSelector
        disableColumnMenu={true}
        initialState={{
          aggregation: {
            model: {
              vacant: 'sum',
              unit: 'sum',
              unrentedNotReady: 'sum',
              unrentedReady: 'sum',
              moreDays: 'sum',
              occupancy: 'avg'
            },
          },
        }}
      />
    </div>
  );
};

export default CurrentAvailability;


// Lagecy Code
// import React from "react";
// import { percentage } from "../../../../functions/decimals";
// import useTableHeight from "../../../../hooks/useTableHeight";
// import useFilterResponse from "../../../../hooks/useFilterResponse";
// import useNoteVisibility from "../../../../hooks/useNoteVisibility";
// import { getNotes } from "../../../../api/api";
// import Note from "../../../../components/note";
// import { useThreshold } from "../../../../hooks/useThreshold";

// const CurrentAvailability = ({ data }) => {
//   const totalUnits = data.reduce((acc, curr) => acc + curr.unit, 0);
//   const totalVacant = data.reduce((acc, curr) => acc + curr.vacant, 0);
//   const totalOccupancy = data.reduce((acc, curr) => acc + curr.occupancy, 0);
//   const totalUnrentedNotReady = data.reduce(
//     (acc, curr) => acc + curr.unrentedNotReady,
//     0
//   );
//   const totalUnrentedReady = data.reduce(
//     (acc, curr) => acc + curr.unrentedReady,
//     0
//   );
//   const totalMoreDays = data.reduce((acc, curr) => acc + curr.moreDays, 0);

//   const columnNames = [
//     "Property",
//     "Units",
//     "Vacant",
//     "Occupancy",
//     "Vacant Unrented Not Ready",
//     "Vacant Unrented Ready",
//     "Vacant > 30 Days",
//     "Next Available",
//   ];

//   const height = useTableHeight(data);
//   const { response: listNotes, loader } = useFilterResponse(
//     getNotes,
//     "notes",
//     "CurrentAvailability"
//   );
//   const {
//     showComponent,
//     componentPosition,
//     handleNote,
//     notes,
//     handleCheckFlag,
//   } = useNoteVisibility(listNotes, loader);

//   const {getThresholdData} = useThreshold({view:'leasing', table: 'CurrentAvailability', loader})

//   return (
//     <div className="container-table" style={{ height: height }}>
//       {showComponent && (
//         <div
//           style={{
//             position: "absolute",
//             left: componentPosition.x,
//             top: componentPosition.y,
//             backgroundColor: "#fff",
//             border: "1px solid #ccc",
//             padding: "10px",
//             zIndex: "10",
//             borderRadius: "10px",
//           }}
//         >
//           <Note
//             notes={notes}
//             columnNames={columnNames}
//             table="CurrentAvailability"
//           />
//         </div>
//       )}
//       <div className="wrapper">
//         <table className="hasColors">
//           <thead>
//             <tr>
//               {columnNames.map((column) => {
//                 return <th key={column}>{column}</th>;
//               })}
//             </tr>
//           </thead>
//           <tbody>
//             {data?.map((item, index) => (
//               <tr key={index}>
//                 <td
//                   onClick={handleNote(item?.propertyID, 0)}
//                   className={`${handleCheckFlag(item?.propertyID, 0)} ${index%2 === 0 ? 'white' : ''}`}
//                 >
//                   {item?.name}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 1,item?.unit)}
//                   className={handleCheckFlag(item?.propertyID, 1)}
//                 >
//                   {item?.unit}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 2, item?.vacant)}
//                   className={handleCheckFlag(item?.propertyID, 2)}
//                 >
//                   {item?.vacant}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 3, `${percentage(item?.occupancy)}%`)}
//                   className={`${
//                     getThresholdData(parseInt(item?.occupancy), 'Occupancy')
//                   } ${handleCheckFlag(item?.propertyID, 3)}`}
//                 >
//                   {percentage(item?.occupancy)}%
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 4, item?.unrentedNotReady)}
//                   className={handleCheckFlag(item?.propertyID, 4)}
//                 >
//                   {item?.unrentedNotReady}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 5, item?.unrentedReady)}
//                   className={handleCheckFlag(item?.propertyID, 5)}
//                 >
//                   {item?.unrentedReady}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 6, item?.moreDays)}
//                   className={`${
//                     getThresholdData(parseInt(item?.moreDays), 'VacantGreaterXDays')
//                   } ${handleCheckFlag(item?.propertyID, 6)}`}
//                 >
//                   {item?.moreDays}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 7, item?.nextAvailable)}
//                   className={handleCheckFlag(item?.propertyID, 7)}
//                 >
//                   {item?.nextAvailable}
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//           <tfoot>
//             <tr>
//               <td>Total</td>
//               <td>{totalUnits}</td>
//               <td>{totalVacant}</td>
//               <td>{percentage(totalOccupancy / data.length)}%</td>
//               <td>{totalUnrentedNotReady}</td>
//               <td>{totalUnrentedReady}</td>
//               <td>{totalMoreDays}</td>
//               <td></td>
//             </tr>
//           </tfoot>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default CurrentAvailability;
