import React, {useState, useRef, useEffect} from "react";
import useTableHeight from "../../../../hooks/useTableHeight";
import { getNotes } from "../../../../api/api";
import useFilterResponse from "../../../../hooks/useFilterResponse";
import useNoteVisibility from "../../../../hooks/useNoteVisibility";
import Note from "../../../../components/note";
import { DataGridPremium, useGridApiRef } from "@mui/x-data-grid-premium";
import useMUIFilter from "../../../../hooks/useMUIFilter";
import ExportToolbar from "../../../../components/exportToolBar";
import { Tooltip, Typography } from '@mui/material';
import tooltips from '../../../../config/tooltips.json'
import Fade from '@mui/material/Fade';
import adjustColumnWidth from "../../../../functions/columns";

const LeasingSucess = ({ data }) => {

  const [pageWidth, setPageWidth] = useState(null)
  const [width, setWidth] = useState(0);
  const elementRef = useRef(null);

  let functionDelaytimeOut

  window.addEventListener('resize', (size) => {
    let currentTarget = size?.currentTarget?.innerWidth
    if (functionDelaytimeOut) {
      clearTimeout(functionDelaytimeOut);
    }
    functionDelaytimeOut = setTimeout(() => {
      setPageWidth(currentTarget);
    }, 800);
  });

  useEffect(() => {
    setWidth(elementRef.current.getBoundingClientRect().width);
    return () => {
      if (functionDelaytimeOut) {
        clearTimeout(functionDelaytimeOut);
      }
    };
  }, [pageWidth]);

  const column = [
    {name:"Property",type:'singleSelect'},
    {name:"New Leads MTD",type:'number'},
    {name:"New Leads Goals",type:'number'},
    {name:"Showings MTD",type:'number'},
    {name:"Showings Goals",type:'number'},
    {name:"Applications MTD",type:'number'},
    {name:"Applications Goals",type:'number'},
    {name:"Move Ins MTD",type:'number'},
    {name:"Move Ins Goals",type:'number'}
  ];

  const columnNames = adjustColumnWidth(column, 100, 8)

    const notesColumns = [
      "Property",
      "New Leads MTD",
      "New Leads Goals",
      "Showings MTD",
      "Showings Goals",
      "Applications MTD",
      "Applications Goals",
      "Move Ins MTD",
      "Move Ins Goals",
    ];

  const columnGroupingModel = [
    {
      groupId: 'New Leads',
      headerName: "New Leads",
      headerAlign: 'center',
      children: [{ field: 'newLeadsMTD' }, { field: 'newLeadsGoals' }],
    },
    {
      groupId: 'Showings',
      headerName: "Showings",
      headerAlign: 'center',
      children: [{ field: 'showingsMTD' }, { field: 'showingsGoals' }],
    },
    {
      groupId: 'Applications',
      headerName: "Applications",
      headerAlign: 'center',
      children: [{ field: 'applicationsMTD' }, { field: 'applicationGoals' }],
    },
    {
      groupId: 'Move Ins',
      headerName: "Move Ins",
      headerAlign: 'center',
      children: [{ field: 'moveInsMTD' }, { field: 'moveInsGoals' }],
    },
  ];

  let { columenGen } = useMUIFilter(
    data,
    columnNames,
    // "Leasing Success"
  );

  const apiRef = useGridApiRef();
  let columns = columenGen();

  const height = useTableHeight(data);
  const { response: listNotes, loader } = useFilterResponse(
    getNotes,
    "notes",
    "LeasingSuccess"
  );
  const {
    showComponent,
    componentPosition,
    handleNote,
    notes,
    handleCheckFlag,
  } = useNoteVisibility(listNotes, loader);

  // if (apiRef?.current?.autosizeColumns) {
  //   apiRef.current.autosizeColumns({
  //     includeHeaders: true,
  //     includeOutliers: true,
  //   });
  // }

  let columnTooltips = columnNames.map((data, index)=>{

    let headerName
if(data.headerName.includes('MTD')){
   headerName = 'MTD'
}else if(data.headerName.includes('Goals')){
  headerName = 'Goals'
};

   data.renderHeader =  (params)=>{ 
     return(
       <div>
                        <Tooltip 
                        placement="top" 
                        arrow
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        title={
                            <React.Fragment>
                              <Typography color="inherit">{tooltips['Leasing Success'][1].tooltip.header}</Typography>
                              {tooltips['Leasing Success'][1].tooltip.text}
                            </React.Fragment>
                          }>
                            <p>{headerName}</p>
                          </Tooltip>
                      </div>
                    )}
                    return data
  })

  return (
    <div
      className="container-table__rental"
      style={{ height: height }}
      id="LeasingSuccess"
      ref={elementRef}
    >
      {showComponent && (
        <div
          style={{
            position: "absolute",
            left: componentPosition.x-350,
            top: componentPosition.y,
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            padding: "10px",
            zIndex: "10",
            borderRadius: "10px",
          }}
        >
          <Note
            notes={notes}
            columnNames={notesColumns}
            table="LeasingSuccess"
            tableFullName="Leasing Success"
            tab="Leasing"
          />
        </div>
      )}
      <DataGridPremium
        className="grid-style"
        slots={{
          toolbar: ExportToolbar,
        }}
        apiRef={apiRef}
        rows={data}
        columns={columnTooltips}
        onCellClick={(cell, event) => {
          handleNote(
            cell.row.propertyID,
            cell.colDef.colNum,
            cell.formattedValue
          )(event);
        }}
        disableColumnMenu={true}
        columnGroupingModel={columnGroupingModel}
        experimentalFeatures={{ columnGrouping: true }}
        pinnedColumns={{left: ['name']}}
        hideFooter
        disableVirtualization
        disableColumnSelector
        getCellClassName={(params) => {
          return `${handleCheckFlag(params.row.propertyID, params.colDef.colNum)}`
        }}
        initialState={{
          aggregation: {
            model: {
              newLeadsMTD: 'sum',
              newLeadsGoals: 'sum',
              showingsMTD:'sum',
              showingsGoals:'sum',
              applicationsMTD:'sum',
              applicationGoals:'sum',
              moveInsMTD:'sum',
              moveInsGoals:'sum'
            },
          },
        }}
      />
    </div>
  );
};

export default LeasingSucess;

//legacy Code
// import React from "react";
// import { percentage } from "../../../../functions/decimals";
// import useTableHeight from "../../../../hooks/useTableHeight";
// import { getNotes } from "../../../../api/api";
// import useFilterResponse from "../../../../hooks/useFilterResponse";
// import useNoteVisibility from "../../../../hooks/useNoteVisibility";
// import Note from "../../../../components/note";

// const LeasingSucess = ({ data }) => {

//   const totalNewLeadsMTD = data.reduce(
//     (acc, curr) => acc + curr.newLeads?.MTD,
//     0
//   );
//   const totalNewLeadsGoals = data.reduce(
//     (acc, curr) => acc + curr.newLeads?.Goals,
//     0
//   );
//   const totalShowingsMTD = data.reduce(
//     (acc, curr) => acc + curr.showings?.MTD,
//     0
//   );
//   const totalShowingsGoals = data.reduce(
//     (acc, curr) => acc + curr.showings?.Goals,
//     0
//   );
//   const totalApplicationsMTD = data.reduce(
//     (acc, curr) => acc + curr.applications?.MTD,
//     0
//   );
//   const totalApplicationsGoals = data.reduce(
//     (acc, curr) => acc + curr.applications?.Goals,
//     0
//   );
//   const totalMoveInsMTD = data.reduce(
//     (acc, curr) => acc + curr.moveIns?.MTD,
//     0
//   );
//   const totalMoveInsGoals = data.reduce(
//     (acc, curr) => acc + curr.moveIns?.Goals,
//     0
//   );

//   const columnNames = [
//     "Property",
//     "New Leads MTD",
//     "New Leads Goals",
//     "Showings MTD",
//     "Showings Goals",
//     "Applications MTD",
//     "Applications Goals",
//     "Move Ins MTD",
//     "Move Ins Goals",
//   ];

//   const height = useTableHeight(data);
//   const { response: listNotes, loader } = useFilterResponse(
//     getNotes,
//     "notes",
//     "LeasingSuccess"
//   );
//   const {
//     showComponent,
//     componentPosition,
//     handleNote,
//     notes,
//     handleCheckFlag,
//   } = useNoteVisibility(listNotes, loader);

//   return (
//     <div className="container-table__rental" style={{ height: height }} id='LeasingSuccess'>
//       {showComponent && (
//         <div
//           style={{
//             position: "absolute",
//             left: componentPosition.x,
//             top: componentPosition.y,
//             backgroundColor: "#fff",
//             border: "1px solid #ccc",
//             padding: "10px",
//             zIndex: "10",
//             borderRadius: "10px",
//           }}
//         >
//           <Note
//             notes={notes}
//             columnNames={columnNames}
//             table="LeasingSuccess"
//           />
//         </div>
//       )}
//       <div className="wrapper">
//         <table className="hasColors">
//           <thead>
//             <tr>
//               <th>Property</th>
//               <th colSpan={2} className="subparent">
//                 New Leads
//               </th>
//               <th colSpan={2} className="subparent">
//                 Showings
//               </th>
//               <th colSpan={2} className="subparent">
//                 Applications
//               </th>
//               <th colSpan={2} className="subparent">
//                 Move Ins
//               </th>
//             </tr>
//             <tr className="subchild">
//               <th></th>
//               <th>MTD</th>
//               <th>Goals</th>
//               <th>MTD</th>
//               <th>Goals</th>
//               <th>MTD</th>
//               <th>Goals</th>
//               <th>MTD</th>
//               <th>Goals</th>
//             </tr>
//           </thead>
//           <tbody>
//             {data?.map((item, index) => (
//               <tr key={index}>
//                 <td
//                   onClick={handleNote(item?.propertyID, 0, item?.name)}
//                   className={`${handleCheckFlag(item?.propertyID, 0)} ${index%2 === 0 ? 'white' : ''}`}
//                 >
//                   {item?.name}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 1, item?.newLeads?.MTD)}
//                   className={`${
//                     item?.newLeads?.MTD === 0
//                       ? "yellow"
//                       : item?.newLeads?.MTD >= 40
//                       ? "green"
//                       : "red"
//                   } ${handleCheckFlag(item?.propertyID, 1)}`}
//                 >
//                   {item?.newLeads?.MTD}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 2, item?.newLeads?.Goals)}
//                   className={handleCheckFlag(item?.propertyID, 2)}
//                 >
//                   {percentage(item?.newLeads?.Goals)}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 3, item?.showings?.MTD)}
//                   className={`${
//                     item?.showings?.MTD === 0
//                       ? "yellow"
//                       : item?.showings?.MTD >= 9
//                       ? "green"
//                       : "red"
//                   } ${handleCheckFlag(item?.propertyID, 3)}`}
//                 >
//                   {item?.showings?.MTD}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 4, item?.showings?.Goals)}
//                   className={handleCheckFlag(item?.propertyID, 4)}
//                 >
//                   {percentage(item?.showings?.Goals)}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 5, item?.applications?.MTD)}
//                   className={`${
//                     item?.applications?.MTD >= 97 ? "green" : "red"
//                   } ${handleCheckFlag(item?.propertyID, 5)}`}
//                 >
//                   {item?.applications?.MTD}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 6, item?.applications?.Goals)}
//                   className={handleCheckFlag(item?.propertyID, 6)}
//                 >
//                   {percentage(item?.applications?.Goals)}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 7, item?.moveIns?.MTD)}
//                   className={`${
//                     item?.moveIns?.MTD >= 97 ? "green" : "red"
//                   } ${handleCheckFlag(item?.propertyID, 7)}`}
//                 >
//                   {item?.moveIns?.MTD}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 8, item?.moveIns?.Goals)}
//                   className={handleCheckFlag(item?.propertyID, 8)}
//                 >
//                   {percentage(item?.moveIns?.Goals)}
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//           <tfoot>
//             <tr>
//               <td>Total</td>
//               <td>{totalNewLeadsMTD.toFixed(0)}</td>
//               <td>{totalNewLeadsGoals.toFixed(0)}</td>
//               <td>{totalShowingsMTD.toFixed(0)}</td>
//               <td>{totalShowingsGoals.toFixed(0)}</td>
//               <td>{totalApplicationsMTD.toFixed(0)}</td>
//               <td>{totalApplicationsGoals.toFixed(0)}</td>
//               <td>{totalMoveInsMTD.toFixed(0)}</td>
//               <td>{totalMoveInsGoals.toFixed(0)}</td>
//             </tr>
//           </tfoot>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default LeasingSucess;
