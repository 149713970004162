import React, {useEffect, useRef, useState} from "react";
import Note from "../../../../components/note";
import useFilterResponse from "../../../../hooks/useFilterResponse";
import { getNotes } from "../../../../api/api";
import useNoteVisibility from "../../../../hooks/useNoteVisibility";
import useTableHeight from "../../../../hooks/useTableHeight";
import { useThreshold } from "../../../../hooks/useThreshold";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import useMUIFilter from "../../../../hooks/useMUIFilter";
import ExportToolbar from "../../../../components/exportToolBar";
import CellWithTracer from "../../../../components/cellTracer/CellTracer";
import adjustColumnWidth from "../../../../functions/columns";

const MaintenanceTable = ({ data }) => {

  const [width, setWidth] = useState(0);
  const elementRef = useRef(null);

  useEffect(() => {
    setWidth(elementRef.current.getBoundingClientRect().width);
  }, []);

  const column = [
    {name:"Property",type:'singleSelect'},
    {name:"BLDG Unit",type:'singleSelect'},
    {name:"Status",type:'singleSelect'},
    {name:"Days Open",type:'singleSelect'},
    {name:"Created",type:'singleSelect'},
    {name:"Priority",type:'singleSelect'},
    {name:"Problem",type:'singleSelect'},
    {name:"Location",type:'singleSelect'},
    {name:"Description"},
  ]

  const columnNames = adjustColumnWidth(column, 100, 15)

  const thresHoldValues = {
    open:{
      thresHoldValue:'DaysOpen'
    },
  }

  const { response: listNotes, loader } = useFilterResponse(
    getNotes,
    "notes",
    "MaintenanceMoreDetails"
  );
  const {
    showComponent,
    componentPosition,
    handleNote,
    notes,
    handleCheckFlag,
    handleCheckFlagforUnits
  } = useNoteVisibility(listNotes, loader);
  const height = useTableHeight(data, 70, '400px');
  const {getThresholdData} = useThreshold({view:'maintenance', table: 'MoreDetailsMaintenance', loader})

  let { columenGen, thresHolds, notesColumnNameGen } = useMUIFilter(data, columnNames);

  let columns = columenGen();

  return (
    <div className="container-table__big2" style={{height: '65vh'}} ref={elementRef}>
      {showComponent && (
        <div
          style={{
            position: "absolute",
            left: componentPosition.x-350,
            top: componentPosition.y,
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            padding: "10px",
            zIndex: "10",
            borderRadius: "10px",
          }}
        >
          <Note
            notes={notes}
            columnNames={notesColumnNameGen()}
            table="MaintenanceMoreDetails"
            tableFullName="Work Orders"
            tab="Maintenance"
          />
        </div>
      )}
       <DataGridPremium
             sx={{
               '&&& .updated': {
                 transition: (theme) =>
                 theme.transitions.create(['background-color', 'outline'], {
                   duration: theme.transitions.duration.standard,
                  }),
                },
                '&&& .updating': {
                  backgroundColor: 'rgb(92 199 68 / 20%)',
                  outline: '1px solid rgb(92 199 68 / 35%)',
                  outlineOffset: '-1px',
                  transition: 'none',
                },
                '&&& .MuiDataGrid-cellContent': {
                  overflowWrap: 'break-word',
                  whiteSpace: 'normal',
                },
              }}
              className="grid-style"
              slots={{
                toolbar: ExportToolbar, cell:CellWithTracer,
              }}
              // pinnedColumns={{left: ['name', 'bdlg']}}
              rows={data}
              columns={columns}
              getRowHeight={()=> 'auto'}
        disableColumnMenu={true}
        hideFooter
        disableVirtualization
        disableColumnSelector
        getCellClassName={(params) => {
          return `${thresHolds(params, getThresholdData, thresHoldValues)} ${handleCheckFlagforUnits(params.row.propertyID, params.colDef.colNum, params.row.bdlg)}`
        }}
          onCellClick={(cell, event) => {
            handleNote(
              cell.row.propertyID,
              cell.colDef.colNum,
              cell.formattedValue,
              cell.row.bdlg
              )(event);
            }}
      />
    </div>
  );
};

export default MaintenanceTable;


//Legacy code
// import React from "react";
// import Note from "../../../../components/note";
// import useFilterResponse from "../../../../hooks/useFilterResponse";
// import { getNotes } from "../../../../api/api";
// import useNoteVisibility from "../../../../hooks/useNoteVisibility";
// import useTableHeight from "../../../../hooks/useTableHeight";
// import { useThreshold } from "../../../../hooks/useThreshold";

// const MaintenanceTable = ({ data }) => {
//   const columnNames = [
//     {name:"Property",type:'singleSelect'},
//     {name:"BLDG Unit",type:'singleSelect'},
//     {name:"Status",type:'singleSelect'},
//     {name:"Days Open",type:'singleSelect'},
//     {name:"Created",type:'singleSelect'},
//     {name:"Priority",type:'singleSelect'},
//     {name:"Problem",type:'singleSelect'},
//     {name:"Location",type:'singleSelect'},
//     {name:"Description",type:'singleSelect'},
//   ]
//   const { response: listNotes, loader } = useFilterResponse(
//     getNotes,
//     "notes",
//     "MaintenanceMoreDetails"
//   );
//   const {
//     showComponent,
//     componentPosition,
//     handleNote,
//     notes,
//     handleCheckFlag,
//     handleCheckFlagforUnits
//   } = useNoteVisibility(listNotes, loader);
//   const height = useTableHeight(data, 70, '400px');
//   const {getThresholdData} = useThreshold({view:'maintenance', table: 'MoreDetailsMaintenance', loader})

//   return (
//     <div className="container-table__big2" style={{height: '60vh'}}>
//       {showComponent && (
//         <div
//           style={{
//             position: "absolute",
//             left: componentPosition.x,
//             top: componentPosition.y,
//             backgroundColor: "#fff",
//             border: "1px solid #ccc",
//             padding: "10px",
//             zIndex: "10",
//             borderRadius: "10px",
//           }}
//         >
//           <Note
//             notes={notes}
//             columnNames={columnNames}
//             table="MaintenanceMoreDetails"
//           />
//         </div>
//       )}
//       <div className="wrapper">
//         <table className="hasColors">
//           <thead>
//             <tr>
//               {columnNames.map((column) => {
//                 return <th key={column}>{column}</th>;
//               })}
//             </tr>
//           </thead>
//           <tbody>
//             {data?.map((item, index) => (
//               <tr key={index}>
//                 <td /*onClick={handleNote(item?.propertyID, 0)} */ className={`${handleCheckFlagforUnits(item?.propertyID, 0, item?.bdlg)} ${index%2 === 0 ? 'white' : ''}`}>{item?.name}</td>
//                 <td /*onClick={handleNote(item?.propertyID, 1)}*/ className={handleCheckFlagforUnits(item?.propertyID, 1, item?.bdlg)}>{item?.bdlg}</td>
//                 <td onClick={handleNote(item?.propertyID, 2, item?.status, item?.bdlg)} className={handleCheckFlagforUnits(item?.propertyID, 2, item?.bdlg)}>{item?.status}</td>
//                 <td onClick={handleNote(item?.propertyID, 3, item?.open, item?.bdlg)} className={`${handleCheckFlagforUnits(item?.propertyID, 3, item?.bdlg)} ${getThresholdData(parseInt(item?.open), 'DaysOpen')}`}>{item?.open}</td>
//                 <td onClick={handleNote(item?.propertyID, 4, item?.created, item?.bdlg)} className={handleCheckFlagforUnits(item?.propertyID, 4, item?.bdlg)}>{item?.created}</td>
//                 <td onClick={handleNote(item?.propertyID, 5, item?.priority, item?.bdlg)} className={handleCheckFlagforUnits(item?.propertyID, 5, item?.bdlg)}>{item?.priority}</td>
//                 <td onClick={handleNote(item?.propertyID, 6, item?.problem, item?.bdlg)} className={handleCheckFlagforUnits(item?.propertyID, 6, item?.bdlg)}>{item?.problem}</td>
//                 <td onClick={handleNote(item?.propertyID, 7, item?.location, item?.bdlg)} className={handleCheckFlagforUnits(item?.propertyID, 7, item?.bdlg)}>{item?.location}</td>
//                 <td onClick={handleNote(item?.propertyID, 8, item?.description, item?.bdlg)} className={handleCheckFlagforUnits(item?.propertyID, 8, item?.bdlg)}>{item?.description}</td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default MaintenanceTable;
