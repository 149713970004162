export default function adjustColumnWidth (columns, defaultMinWidth, multiplier, columnGroup) {

    const largerColumns = {
        Description: {
        name: 'Description',
        multiplier: 3
        },
        Property: {
            name: "Property",
            multiplier: 2
        },
        TurnoverRate: {
            name: 'Turnover Rate',
            multiplier: 1.5
        },
        TurnsLessThan30Days: {
            name: 'Turns > 30 Days',
            multiplier: 1.5
        },
        Current_Turns: {
            name: 'Current Turns',
            multiplier: 1.5
        },
        Open: {
            name: 'Open',
            multiplier: 1.5
        },
        EOM: {
            name: 'EOM',
            multiplier: 1.5
        },
        Trend: {
            name: 'Trend',
            multiplier: 1.7
        },
        Vacant: {
            name: 'Vacant',
            multiplier: 1.5
        },
        'Units': {
            multiplier: 1
        },
        'Occupancy': {
            multiplier: 1.2
        },
        'Move In': {
            multiplier: 1.1
        },
        'Applications Completed': {
            multiplier: 5
        },

        // Renewals more details table
        Unit: {
            multiplier: 1
        },
        'Renewal Status': {
            multiplier: 1.6
        },
        'Current Lease End': {
            multiplier: 1.8
        },
        'Renewal Sent By': {
            multiplier: 1.9
        },
        'Renewal Due Back By': {
            multiplier: 2
        },
        'Renewal Increase $': {
            multiplier: 1.8
        },
        'Renewal Increase %': {
            multiplier: 1.8
        },
        'Current Lease End': {
            multiplier: 1.75
        },

        //collection budget variance table columns
        'Account Group': {
            multiplier: 1.5
        },
        'Account #': {
            multiplier: 1.5
        },
        'Account Name': {
            multiplier: 1.8
        },

        //leasing availability table columns
        'Move-Out': {
            multiplier: 1.25
        },
        'Days Vacant': {
            multiplier: 1.3
        },
        'Days Until Move-Out': {
            multiplier: 1.8
        },
        'Expected Days Vacant': {
            multiplier: 1.2
        },
        'Application Completed': {
            multiplier: 2
        },
        'Days to Rent': {
            multiplier: 1.35
        },
        'Expected Days Vacant': {
            multiplier: 2
        },
        'Days to Process': {
            multiplier: 1.75
        },
        'Days To Sign': {
            multiplier: 1.5
        },
        'Lease Completed': {
            multiplier: 1.8
        },
        'Lead': {
            multiplier: 1.5
        },
        'Scheduled Move-In': {
            multiplier: 1.75
        },
        'Available On': {
            multiplier: 1.5
        },
        'Applications Completed Actual': {
            multiplier: 1.5
        },
        'Applications Completed %': {
            multiplier: 1.5
        },
        'Applications Approved Actual': {
            multiplier: 1.5
        },
        'Applications Approved %': {
            multiplier: 1.5
        },
        'Leases Approved Actual': {
            multiplier: 1.5
        },
        'Leases Approved %': {
            multiplier: 1.5
        },
    }

    return columns.map((column) => {
        const calculatedMinWidth = largerColumns[column.name] ? defaultMinWidth * largerColumns[column.name].multiplier
        : column.name.length * multiplier || defaultMinWidth;

        return {
            ...column,
            minWidth : column.minWidth || calculatedMinWidth,
            flex: 1,
            headerClassName: column.headerClassName || 'custom-header',
            renderHeader: (params) => (
                <div
                    style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                    >
                        {params.colDef.headerName}
                    </div>
                ),
            };
    });
}
