import React, {useRef, useEffect, useState} from "react";
import useTableHeight from "../../../../hooks/useTableHeight";
import { getNotes } from "../../../../api/api";
import useFilterResponse from "../../../../hooks/useFilterResponse";
import useNoteVisibility from "../../../../hooks/useNoteVisibility";
import Note from "../../../../components/note";
import { useThreshold } from "../../../../hooks/useThreshold";
import { DataGridPremium, useGridApiRef } from "@mui/x-data-grid-premium";
import useMUIFilter from "../../../../hooks/useMUIFilter";
import ExportToolbar from "../../../../components/exportToolBar";
import adjustColumnWidth from "../../../../functions/columns";
import { AdUnits } from "@mui/icons-material";

const RentalTimeline = ({ data }) => {

  const [pageWidth, setPageWidth] = useState(null)
  const [width, setWidth] = useState(0);
  const elementRef = useRef(null);

  let functionDelaytimeOut

  window.addEventListener('resize', (size) => {
    let currentTarget = size?.currentTarget?.innerWidth
    if (functionDelaytimeOut) {
      clearTimeout(functionDelaytimeOut);
    }
    functionDelaytimeOut = setTimeout(() => {
      setPageWidth(currentTarget);
    }, 800);
  });

  useEffect(() => {
    setWidth(elementRef.current.getBoundingClientRect().width);
    return () => {
      if (functionDelaytimeOut) {
        clearTimeout(functionDelaytimeOut);
      }
    };
  }, [pageWidth]);

  const column = [
    {name:"Property", type:'singleSelect'},
    {name:"Apps in progress", type:'number'},
    {name:"Avg. Days to Process", type:'number', valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `${params.value.toFixed(1).toLocaleString()}`;
    }},
    {name:"Unsigned Leases > 3 Days", type:'number'},
    {name:"Avg Days on Market", type:'number', valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `${params.value.toFixed(1).toLocaleString()}`;
    }},
    {name:"Forecasted Days Vacant", type:'number', valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `${params.value.toFixed(1).toLocaleString()}`;
    }},
  ];

  const columnNames = adjustColumnWidth(column, 100, 11)

  const thresHoldValues = {
    inProgress:{
      thresHoldValue: 'AvgDaysToProcess'
    },
    avg:{
      thresHoldValue: 'AvgDaysOnMarket'
    },
    unsigned:{
      thresHoldValue: 'UnsignedLeasesGreaterXDays'
    },
    avgMarket:{
      thresHoldValue: 'AvgDaysOnMarket'
    },
    forecasted:{
      thresHoldValue: 'ForecastedDaysVacant'
    }
}

  const apiRef = useGridApiRef();
  const height = useTableHeight(data);
  const { response: listNotes, loader } = useFilterResponse(
    getNotes,
    "notes",
    "RentalTimeline"
  );
  const {
    showComponent,
    componentPosition,
    handleNote,
    notes,
    handleCheckFlag,
  } = useNoteVisibility(listNotes, loader);
  const {getThresholdData} = useThreshold({view:'leasing', table: 'RentalTimeline', loader})

  let { columenGen, thresHolds, notesColumnNameGen } = useMUIFilter(
    data,
    columnNames,
    "Rental Timeline"
  );

  let columns = columenGen();

  // if (apiRef?.current?.autosizeColumns) {
  //   apiRef.current.autosizeColumns({
  //     includeHeaders: true,
  //     includeOutliers: true,
  //   });
  // }

  return (
    <div className="container-table__rental" style={{ height: height }} ref={elementRef}>
      {showComponent && (
        <div
          style={{
            position: "absolute",
            left: componentPosition.x-350,
            top: componentPosition.y,
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            padding: "10px",
            zIndex: "10",
            borderRadius: "10px",
          }}
        >
          <Note
            notes={notes}
            columnNames={notesColumnNameGen()}
            table="RentalTimeline"
            tableFullName="Rental Timeline"
            tab="Leasing"
          />
        </div>
      )}
<DataGridPremium
  className="grid-style"
  apiRef={apiRef}
  rows={data}
  columns={columns}
  onCellClick={(cell,event)=>{handleNote(cell.row.propertyID, cell.colDef.colNum, cell.formattedValue)(event)}}
  getCellClassName={(params) => {
    return `${thresHolds(params, getThresholdData,thresHoldValues)} ${handleCheckFlag(params.row.propertyID, params.colDef.colNum)}`
  }}
  slots={{ toolbar: ExportToolbar}}
  disableColumnMenu={true}
  pinnedColumns={{left: ['name']}}
  hideFooter
  disableColumnSelector
  disableVirtualization
  initialState={{
    aggregation: {
      model: {
        inProgress: 'sum',
        avg: 'avg',
        unsigned:'sum',
        avgMarket:'avg',
        forecasted: 'avg'
      },
    },
  }}
/>
    </div>
  );
};

export default RentalTimeline;


//Legacy Code
// import React from "react";
// import useTableHeight from "../../../../hooks/useTableHeight";
// import { getNotes } from "../../../../api/api";
// import useFilterResponse from "../../../../hooks/useFilterResponse";
// import useNoteVisibility from "../../../../hooks/useNoteVisibility";
// import Note from "../../../../components/note";
// import { useThreshold } from "../../../../hooks/useThreshold";

// const RentalTimeline = ({ data }) => {
//   const totalInProgress = data.reduce((acc, curr) => acc + curr.inProgress, 0);
//   const totalAvg = data.reduce((acc, curr) => acc + curr.avg, 0);
//   const totalUnsigned = data.reduce((acc, curr) => acc + curr.unsigned, 0);
//   const totalAvgMarket = data.reduce((acc, curr) => acc + curr.avgMarket, 0);
//   const columnNames = [
//     "Property",
//     "Apps in progress",
//     "Avg. Days to Process",
//     "Unsigned Leases > 3 Days",
//     "Avg Days on Market",
//     "Forecasted Days Vacant",
//   ];

//   const height = useTableHeight(data);
//   const { response: listNotes, loader } = useFilterResponse(
//     getNotes,
//     "notes",
//     "RentalTimeline"
//   );
//   const {
//     showComponent,
//     componentPosition,
//     handleNote,
//     notes,
//     handleCheckFlag,
//   } = useNoteVisibility(listNotes, loader);
//   const {getThresholdData} = useThreshold({view:'leasing', table: 'RentalTimeline', loader})

//   return (
//     <div className="container-table__rental" style={{ height: height }}>
//       {showComponent && (
//         <div
//           style={{
//             position: "absolute",
//             left: componentPosition.x,
//             top: componentPosition.y,
//             backgroundColor: "#fff",
//             border: "1px solid #ccc",
//             padding: "10px",
//             zIndex: "10",
//             borderRadius: "10px",
//           }}
//         >
//           <Note
//             notes={notes}
//             columnNames={columnNames}
//             table="RentalTimeline"
//           />
//         </div>
//       )}
//       <div className="wrapper">
//         <table className="hasColors">
//           <thead>
//             <tr>
//               {columnNames.map((column) => {
//                 return <th key={column}>{column}</th>;
//               })}
//             </tr>
//           </thead>
//           <tbody>
//             {data?.map((item, index) => (
//               <tr key={index}>
//                 <td
//                   onClick={handleNote(item?.propertyID, 0, item?.name)}
//                   className={`${handleCheckFlag(item?.propertyID, 0)} ${index%2 === 0 ? 'white' : ''}`}
//                 >
//                   {item?.name}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 1, item?.inProgress)}
//                   className={handleCheckFlag(item?.propertyID, 1)}
//                 >
//                   {parseInt(item?.inProgress)}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 2, item?.avg)}
//                   className={`${getThresholdData(parseInt(item?.avg), 'AvgDaysToProcess')} ${handleCheckFlag(item?.propertyID, 2)}`}
//                 >
//                   {parseInt(item?.avg)}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 3, item?.unsigned)}
//                   className={`${
//                     getThresholdData(parseInt(item?.unsigned), 'UnsignedLeasesGreaterXDays')
//                   } ${handleCheckFlag(item?.propertyID, 3)}`}
//                 >
//                   {parseInt(item?.unsigned)}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 4, item?.avgMarket)}
//                   className={`${
//                     getThresholdData(parseInt(item?.avgMarket), 'AvgDaysOnMarket')
//                   } ${handleCheckFlag(item?.propertyID, 4)}`}
//                 >
//                   {parseInt(item?.avgMarket)}
//                 </td>
//                 <td
//                   onClick={handleNote(item?.propertyID, 5, item?.forecasted)}
//                   className={`${
//                     getThresholdData(parseInt(item?.forecasted), 'ForecastedDaysVacant')
//                   } ${handleCheckFlag(item?.propertyID, 5)}`}
//                 >
//                   {parseInt(item?.forecasted)}
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//           <tfoot>
//             <tr>
//               <td>Total</td>
//               <td>{parseInt(totalInProgress)}</td>
//               <td>{parseInt(totalAvg/data.length)}</td>
//               <td>{parseInt(totalUnsigned)}</td>
//               <td>{parseInt(totalAvgMarket/data.length)}</td>
//               <td></td>
//             </tr>
//           </tfoot>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default RentalTimeline;
