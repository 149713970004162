import React, { useState } from "react";
import "../../../../../styles.scss";
import "../../../../settings/settings.scss";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createUser, createUserAdmin } from "../../../../../api/api";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { GroupsForm } from "../../../../../components/groupsForm";
import { PropertiesForm } from "../../../../../components/propertiesForm";

const CreateUser = () => {
  const navigate = useNavigate();

  const status = useSelector((state) => state.sidebar.value);
  const listGroups = useSelector((state) => state.state.groupList);
  const listProperties = useSelector((state) => state.state.propertiesList);
  const handleRedirectAdminButton = () => navigate("/admin");

  const [toggle, setToggle] = useState(true);
  const [userFirstname, setUserFirstname] = useState("");
  const [userLastname, setUserLastname] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [checkPassword, setCheckPassword] = useState("");
  const [title, setTitle] = useState("");
  const [show, setShow] = useState(false);
  const [error, setError] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [hasErrors, setHasErrors] = useState({
    firstname: true,
    lastname: true,
    email: true,
    password: true,
    checkPass: true,
    title: true,
  });
  const [checked, setChecked] = useState(false);
  const [groupChecked, setGroupChecked] = useState(false);

  const setTable = (event) => {
    if (event.target.value === "EvB") {
      setToggle(true);
    } else {
      setToggle(false);
    }
  };
const onChangeFirstname = (e) => {
    setUserFirstname(e.target.value);
    setHasErrors({ ...hasErrors, firstname: false });
  };
  const onChangeLastname = (e) => {
    setUserLastname(e.target.value);
    setHasErrors({ ...hasErrors, lastname: false });
  };
  const onChangeUserMail = (e) => {
    setUserEmail(e.target.value);
    setHasErrors({ ...hasErrors, email: false });
  };
  const onChangePassword = (e) => {
    setUserPassword(e.target.value);
    setHasErrors({ ...hasErrors, password: false });
  };
  const onChangeCheckPassword = (e) => {
    setCheckPassword(e.target.value);
    setHasErrors({ ...hasErrors, checkPass: false });
  };
  const onChangeTitle = (e) => {
    setTitle(e.target.value);
    setHasErrors({ ...hasErrors, title: false });
  };

  const sendInfo = () => {
    console.log("click");
    setShow(false);
    setError(false);

    if (checkValues()) {
      const filterList = listProperties.filter((item) => item.value === 1);
      const filterGroups = listGroups.filter((item) => item.value === 1);

      if (filterList.length === 0 && filterGroups.length === 0) {

        setAlertText("Select a property");
        setError(true);
        setShow(true);

        return;
      } else {
        setError(false);
        setShow(false);

        if (toggle) {
          console.log("User");
          fetchedCreateUser(filterList, filterGroups);
        } else {
          console.log("Admin");
          fetchedCreateAdminUser(filterList, filterGroups);
        }
      }
    }
  };

  const fetchedCreateUser = async (properties, groups) => {
    try {
      const fetchedUser = await createUser({
        firstName: userFirstname,
        lastName: userLastname,
        email: userEmail,
        password: userPassword,
        title: title,
        propertyGroups: groups,
        properties: properties,
      });

      if (fetchedUser?.token !== "") {
        setError(false);
        setAlertText("User created successfully");
        setShow(true);
        handleRedirectAdminButton();
      } else {
        setError(true);
        setAlertText("Server error");
        setShow(true);
      }
    } catch (e) {
      console.log(e);
      setError(true);
      setAlertText(e.response.data.msg);
      setShow(true);
    }
  };

  const fetchedCreateAdminUser = async (properties, groups) => {
    try {
      const fetchedUser = await createUserAdmin({
        firstName: userFirstname,
        lastName: userLastname,
        email: userEmail,
        password: userPassword,
        title: title,
        propertyGroups: groups,
        properties: properties,
      });

      if (fetchedUser?.token !== "") {
        setError(false);
        setAlertText("User created successfully");
        setShow(true);
        handleRedirectAdminButton();
      } else {
        setError(true);
        setAlertText("Server error");
        setShow(true);
      }
      console.log(fetchedUser);
    } catch (e) {
      console.log(e);
      setError(true);
      setAlertText(e.message);
      setShow(true);
    }
  };

  const checkValues = () => {
    if (userFirstname === "") {
      setHasErrors({ ...hasErrors, firstname: true });
      setAlertText("First Name cannot be empty");
      setError(true);
      setShow(true);

      return false;
    }
    if (userLastname === "") {
      setHasErrors({ ...hasErrors, lastname: true });
      setAlertText("Last Name cannot be empty");
      setError(true);
      setShow(true);
      return false;
    }
    if (userEmail === "") {
      setHasErrors({ ...hasErrors, email: true });
      setAlertText("Email cannot be empty");
      setError(true);
      setShow(true);
      return false;
    }
    const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!userEmail.match(validRegex)) {
      setHasErrors({ ...hasErrors, email: true });
      setAlertText("Email must be in valid format");
      setError(true);
      setShow(true);
      return false;
    }
    if (userPassword === "") {
      setHasErrors({ ...hasErrors, password: true });
      setAlertText("Password cannot be empty");
      setError(true);
      setShow(true);
      return false;
    }
    if (userPassword !== checkPassword) {
      setHasErrors({ ...hasErrors, checkPass: true });
      setAlertText("Password not matched");
      setError(true);
      setShow(true);
      return false;
    }
    if (title === "") {
      setHasErrors({ ...hasErrors, title: true });
      setAlertText("Title cannot be empty");
      setError(true);
      setShow(true);
    }

    setHasErrors({
      firstname: false,
      lastname: false,
      email: false,
      password: false,
      checkPass: false,
      title: false,
    });
    return true;
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShow(false);
  };

  return (
    <div id="main">
      <div className={`content ${status}`}>
        <div className="settings-container">
          <Snackbar
            autoHideDuration={3000}
            onClose={handleClose}
            open={show}
            sx={{ height: "100%" }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Alert
            onClose={handleClose}
              severity={error ? "error" : "success"}
              sx={{ width: "100%" }}
            >
              {alertText}
            </Alert>
          </Snackbar>
          <div>
            <h2>New User</h2>
            <div className="line"></div>
          </div>
          <div className="content-mid">
            <div>
              <div style={{ margin: "2rem 2rem 0 2rem" }}>
                <h3 style={{ marginBottom: "1rem" }}>User Information</h3>
                <div>
                  Role
                  <div onChange={setTable.bind(this)} className="radios">
                    <div>
                      <input
                        type="radio"
                        value="EvB"
                        name="gender"
                        id="blue"
                        checked={toggle}
                      />
                      User
                    </div>
                    <div style={{ marginLeft: "2rem" }}>
                      <input type="radio" value="IvB" name="gender" id="blue" />
                      Admin
                    </div>
                  </div>
                </div>
              </div>
              <div className="settings-middle">
                <label>First Name</label>
                <input
                  className={hasErrors.firstname ? "error" : ""}
                  type="text"
                  onChange={onChangeFirstname}
                ></input>
                <label>Last Name</label>
                <input
                  className={hasErrors.lastname ? "error" : ""}
                  type="text"
                  autoComplete="off"
                  onChange={onChangeLastname}
                ></input>
                <label>Email</label>
                <input
                  className={hasErrors.email ? "error" : ""}
                  type="text"
                  autoComplete="off"
                  onChange={onChangeUserMail}
                ></input>
                <label>Password</label>
                <input
                  className={hasErrors.password ? "error" : ""}
                  type="password"
                  onChange={onChangePassword}
                ></input>
                <label>Confirm Password</label>
                <input
                  className={hasErrors.checkPass ? "error" : ""}
                  type="password"
                  onChange={onChangeCheckPassword}
                ></input>
                <label>Title</label>
                <input
                  className={hasErrors.title ? "error" : ""}
                  type="text"
                  onChange={onChangeTitle}
                ></input>
              </div>
            </div>
            {/* <div className="rightMargin"> */}
                <div style={{ margin: "2rem 2rem 0 2rem" }}>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: "center", marginTop: '-3%' }}>
                <h3>Grouped</h3>
              {/* </div> */}
              <button
                  style={{ alignSelf: 'flex-start' }}
                  onClick={() => setGroupChecked(!groupChecked)}
                >
                  {!groupChecked ? 'Check' : 'Uncheck'} All
                </button>
                  </div>
              <GroupsForm selected={[]} checkAll={groupChecked ? 1:2}/>
                </div>
              <div
                // style={{
                //   display: "flex",
                //   justifyContent: "space-between",
                //   alignItems: "center",
                //   marginTop: "1.5rem",
                // }}
                style={{ margin: "2rem 2rem 0 2rem" }}
              >
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: "center", marginTop: '-3%' }}>
                <h3>Properties</h3>{" "}
                <button onClick={() => setChecked(!checked)}>
                  {!checked ? "Check" : "Uncheck"} All
                </button>
                </div>
              <PropertiesForm checkAll={checked ? 1:2} />
              </div>
          </div>
          <div style={{ margin: "0 2rem 0 2rem" }}>
            <br></br>
          </div>
          <div className="settings-bottom">
            <div>
              <button className="gray" onClick={handleRedirectAdminButton}>
                CANCEL
              </button>
            </div>
            <div>
              <button className="blue" onClick={sendInfo}>
                SAVE CHANGES
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateUser;
