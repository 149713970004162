import React, {useState, useRef, useEffect} from "react";
import Note from "../../../../components/note";
import useTableHeight from "../../../../hooks/useTableHeight";
import useNoteVisibility from "../../../../hooks/useNoteVisibility";
import useFilterResponse from "../../../../hooks/useFilterResponse";
import { getNotes } from "../../../../api/api";
import LineChart from "../../chart";
import { useThreshold } from "../../../../hooks/useThreshold";
import { DataGridPremium, useGridApiRef } from "@mui/x-data-grid-premium";
import useMUIFilter from "../../../../hooks/useMUIFilter";
import ExportToolbar from "../../../../components/exportToolBar";
import adjustColumnWidth from "../../../../functions/columns";

const Projected = ({ data }) => {

  const [pageWidth, setPageWidth] = useState(null)
  const [width, setWidth] = useState(0);
  const elementRef = useRef(null);

  let functionDelaytimeOut

  window.addEventListener('resize', (size) => {
    let currentTarget = size?.currentTarget?.innerWidth
    if (functionDelaytimeOut) {
      clearTimeout(functionDelaytimeOut);
    }
    functionDelaytimeOut = setTimeout(() => {
      setPageWidth(currentTarget);
    }, 800);
  });

  useEffect(() => {
    setWidth(elementRef.current.getBoundingClientRect().width);
    return () => {
      if (functionDelaytimeOut) {
        clearTimeout(functionDelaytimeOut);
      }
    };
  }, [pageWidth]);
  
  const column = [
    { name: "Property", type: "singleSelect", colNum:0},
    { name: "Current", type: "number", colNum:1, valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `${params.value.toFixed(1).toLocaleString()} %`;}},
    { name: "EOM", type: "number", colNum:2, valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `${params.value.toFixed(1).toLocaleString()} %`;}},
    { name: data[0]?.month1.name, type: "number", colNum:3, valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `${params.value.toFixed(1).toLocaleString()} %`;}},
    { name: data[0]?.month2.name, type: "number", colNum:4, valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `${params.value.toFixed(1).toLocaleString()} %`;}},
    { name: data[0]?.month3.name, type: "number", colNum:5, valueFormatter: (params) => {
      if (params.value == null) {
        return '';
      }
      return `${params.value.toFixed(1).toLocaleString()} %`;}},
    {
      colNum:6,
      name: "Trend",
      field: "trend",
      renderCell: (params) => {
        let rowData = {
          month1: {
            name: data[0].month1.name,
            data:params.row.month1
          },
          month2: {
            name: data[0].month2.name,
            data:params.row.month2
          },
          month3: {
            name: data[0].month3.name,
            data:params.row.month3
          },
          oem: {
            data: params.row.oem,
          },
          propertyname: params.row.propertyName,
          percentages: {
            percentage: params.row.percentages,
          },
        };
        return <LineChart row={rowData} />
      },
    },
  ];

  const columnNames = adjustColumnWidth(column, 90, 20)

  let generatedRow = data.map((data) => {
    let propertyID = data.propertyID
    let propertyName = data.propertyName;
    let percentages = data.percentages.percentage;
    let oem = data.oem.data;
    let month1 = data.month1.data;
    let month2 = data.month2.data;
    let month3 = data.month3.data;
    return {
      id: data.id,
      propertyID:data.propertyID,
      propertyName,
      percentages,
      oem,
      month1,
      month2,
      month3,
    };
  });

  const thresHoldValues = {
    percentages:{
      thresHoldValue: 'Current'
    },
    oem:{
      thresHoldValue: 'EOM'
    },
    month1:{
      thresHoldValue: 'Month2'
    },
    month2:{
      thresHoldValue: 'Month3'
    },
    month3:{
      thresHoldValue: 'Month4'
    }
}

let notescolumns = columnNames.map((col)=>{
  return col.name
})


  let { columenGen, thresHolds } = useMUIFilter(data, columnNames);

  const apiRef = useGridApiRef();
  let columns = columenGen();

  const height = useTableHeight(data);
  const { response: listNotes, loader } = useFilterResponse(
    getNotes,
    "notes",
    "ProjectedOccupancy"
  );
  const {
    showComponent,
    componentPosition,
    handleNote,
    notes,
    handleCheckFlag,
  } = useNoteVisibility(listNotes, loader);
  const { getThresholdData } = useThreshold({
    view: "occupancy",
    table: "ProjectedOccupancy",
    loader,
  });

  // if (apiRef?.current?.autosizeColumns) {
  //   apiRef.current.autosizeColumns({
  //     includeHeaders: true,
  //     includeOutliers: true,
  //   });
  // }

  return (
    <div className="container-table__rental" style={{ height: height }} ref={elementRef}>
      {showComponent && (
        <div
          style={{
            position: "absolute",
            left: componentPosition.x-350,
            top: componentPosition.y,
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            padding: "10px",
            zIndex: "10",
            borderRadius: "10px",
          }}
        >
          <Note
            notes={notes}
            columnNames={notescolumns}
            table="ProjectedOccupancy"
            tableFullName="Projected Occupancy"
            tab="Occupancy"
          />
        </div>
      )}
      <DataGridPremium
        className="grid-style"
        apiRef={apiRef}
        rows={generatedRow}
        columns={columns}
        onCellClick={(cell, event) => {
          handleNote(
            cell.row.propertyID,
            cell.colDef.colNum,
            cell.formattedValue
          )(event);
        }}
        slots={{
          toolbar: ExportToolbar,
        }}
        pinnedColumns={{left: ['propertyName']}}
        disableColumnMenu={true}
        disableVirtualization
        hideFooter
        disableColumnSelector
        getCellClassName={(params) => {
          return `${thresHolds(params, getThresholdData,thresHoldValues)} ${handleCheckFlag(params.row.propertyID, params.colDef.colNum)}`
        }}
        initialState={{
          aggregation: {
            model: {
              percentages: 'avg',
              oem: 'avg',
              month1: 'avg',
              month2: 'avg',
              month3: 'avg'
            },
          },
        }}
      />
    </div>
  );
};

export default Projected;

// Legacy Code
// import React from "react";
// import Note from "../../../../components/note";
// import { percentage } from "../../../../functions/decimals";
// import useTableHeight from "../../../../hooks/useTableHeight";
// import useNoteVisibility from "../../../../hooks/useNoteVisibility";
// import useFilterResponse from "../../../../hooks/useFilterResponse";
// import { getNotes } from "../../../../api/api";
// import LineChart from "../../chart";
// import { useThreshold } from "../../../../hooks/useThreshold";

// const Projected = ({ data }) => {
//   const totalCurrent = data?.reduce(
//     (acc, curr) => acc + curr?.percentages.percentage,
//     0
//   );
//   const totalEOM = data?.reduce((acc, curr) => acc + curr?.oem.data, 0);
//   const totalmonth1 = data?.reduce((acc, curr) => acc + curr?.month1.data, 0);
//   const totalmonth2 = data?.reduce((acc, curr) => acc + curr?.month2.data, 0);
//   const totalmonth3 = data?.reduce((acc, curr) => acc + curr?.month3.data, 0);

//   const columnNames = [
//     "Property",
//     "Current",
//     "EOM",
//     data[0]?.month1.name,
//     data[0]?.month2.name,
//     data[0]?.month3.name,
//     "Trend",
//   ];

//   const height = useTableHeight(data);
//   const { response: listNotes, loader } = useFilterResponse(
//     getNotes,
//     "notes",
//     "ProjectedOccupancy"
//   );
//   const {
//     showComponent,
//     componentPosition,
//     handleNote,
//     notes,
//     handleCheckFlag,
//   } = useNoteVisibility(listNotes, loader);
//   const {getThresholdData} = useThreshold({view:'occupancy', table: 'ProjectedOccupancy', loader})

//   return (
//     <div className="container-table__rental" style={{ height: height }}>
//       {showComponent && (
//         <div
//           style={{
//             position: "absolute",
//             left: componentPosition.x,
//             top: componentPosition.y,
//             backgroundColor: "#fff",
//             border: "1px solid #ccc",
//             padding: "10px",
//             zIndex: "10",
//             borderRadius: "10px",
//           }}
//         >
//           <Note notes={notes} columnNames={columnNames} table="ProjectedOccupancy"/>
//         </div>
//       )}
//       <div className="wrapper">
//         <table className="hasColors">
//           <thead>
//             <tr>
//               {columnNames.map((column) => {
//                 return <th key={column}>{column}</th>;
//               })}
//             </tr>
//           </thead>
//           <tbody>
//             {data?.map((item, index) => {
//               return (
//                 <tr key={item.propertyID}>
//                   <td className={`${handleCheckFlag(item?.propertyID, 0)} ${index%2 === 0 ? 'white' : ''}`} onClick={handleNote(item.propertyID, 0, item?.propertyName)}>
//                     {item?.propertyName}
//                   </td>
//                   <td
//                     className={`${getThresholdData(parseInt(item?.percentages.percentage), 'Current')}
//                     ${handleCheckFlag(item.propertyID, 1)}`}
//                     onClick={handleNote(item.propertyID, 1, `${item?.percentages.percentage}%`)}
//                   >
//                     {percentage(item?.percentages.percentage)}%
//                   </td>
//                   <td
//                     className={`${getThresholdData(parseInt(item?.oem.data), 'EOM')}
//                     ${handleCheckFlag(item.propertyID, 2)}`}
//                     onClick={handleNote(item.propertyID, 2, item?.oem.data)}
//                   >
//                     {percentage(item?.oem.data)}%
//                   </td>
//                   <td
//                     className={`${getThresholdData(parseInt(item?.month1.data), 'Month2')}
//                     ${handleCheckFlag(item.propertyID, 3)}`}
//                     onClick={handleNote(item.propertyID, 3,item?.month1.data)}
//                   >
//                     {percentage(item?.month1.data)}%
//                   </td>
//                   <td
//                     className={`${getThresholdData(parseInt(item?.month2.data), 'Month3')}
//                     ${handleCheckFlag(item.propertyID, 4)}`}
//                     onClick={handleNote(item.propertyID, 4, item?.month2.data)}
//                   >
//                     {percentage(item?.month2.data)}%
//                   </td>
//                   <td
//                     className={`${getThresholdData(parseInt(item?.month3.data), 'Month4')}
//                     ${handleCheckFlag(item.propertyID, 5)}`}
//                     onClick={handleNote(item.propertyID, 5, item?.month3.data)}
//                   >
//                     {percentage(item?.month3.data)}%
//                   </td>
//                   <td> <LineChart row={item}/></td>
//                 </tr>
//               );
//             })}
//           </tbody>
//           <tfoot>
//             <tr>
//               <td>Total</td>
//               <td>{percentage(totalCurrent / data?.length)}%</td>
//               <td>{percentage(totalEOM / data?.length)}%</td>
//               <td>{percentage(totalmonth1 / data?.length)}%</td>
//               <td>{percentage(totalmonth2 / data?.length)}%</td>
//               <td>{percentage(totalmonth3 / data?.length)}%</td>
//               <td></td>
//             </tr>
//           </tfoot>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default Projected;
