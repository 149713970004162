import  React  from "react";
import { Line } from "react-chartjs-2";
import {
  Chart,
  LinearScale,
  CategoryScale,
  PointElement,
  LineElement,
  Filler,
  Legend,
  Tooltip,
} from "chart.js";

Chart.register(LinearScale);
Chart.register(CategoryScale);
Chart.register(PointElement);
Chart.register(LineElement);
Chart.register(Filler);
Chart.register(Legend);
Chart.register(Tooltip);

const LineChart = ({ row }) => {
  const labels = ["current","eom",row?.month1.name,row?.month2.name,row?.month3.name,];
  const data = {
    labels: labels,
    datasets: [
      {
        label: row.propertyName,
        data: [
            row?.percentages.percentage,
            row?.oem.data,
            row?.month1.data,
            row?.month2.data,
            row?.month3.data,
        ],
        borderColor: "red",
        backgroundColor: "transparent",
        borderWidth: 2,
        fill: true,
        lineTension: 0,
        pointStyle: "circle",
        pointRadius: 2,
        pointHoverRadius: 4,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        //enabled: true,
        callbacks: {
          label: function(context) {
            return context.parsed.y;
          }
        }
      }
    },
    scales: {
      y: {
        beginAtZero: false,
        display: false,
      },
      x: {
        display: false,
      },
    },
    elements: {
      line: {
        tension: 0,
      },
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
  };

  return (
    <div style={{ maxHeight: '3rem', maxWidth: '8rem'}}>
      <Line data={data} options={options} />
    </div>
  );
};

export default LineChart;
